import React, { useCallback, useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { toast } from "react-hot-toast";
import { useAuthStore } from "store/useAuthStore";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toReadableDate } from "helpers/utils";

const Driver = () => {
  const { id } = useParams();
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [deposit, setDeposit] = useState({} as any);
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  useEffect(() => {
    handleFetchDriver();
  }, []);

  const handleFetchDriver = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/driver/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setDeposit(res.data);
    } catch (error) {
      toast.error("An error occurred while fetching deposit");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleApproveDeposit = useCallback(async () => {
    setApproving(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/deposit/${id}/approve`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setDeposit(res.data);
      toast.success("Deposit approved successfully");
    } catch (error) {
      toast.error("An error occurred while approving deposit");
    } finally {
      setApproving(false);
    }
  }, []);
  const handleDeclineReject = useCallback(async () => {
    setApproving(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/deposit/${id}/reject`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setDeposit(res.data);
      toast.success("Deposit declined successfully");
    } catch (error) {
      toast.error("An error occurred while declining deposit");
    } finally {
      setRejecting(false);
    }
  }, []);

  return (
    <React.Fragment>
      <BreadCrumb title="Drivers" pageTitle="Driver" />
      <ToastContainer closeButton={false} limit={1} />
      {loading ? <p>Loading...</p> : <></>}
      {deposit && (
        <div className="bg-white shadow-md rounded-md p-4 w-full">
          <div className="flex justify-between">
            <div className="w-2/3 ">
              <div className="flex flex-row gap-2 items-center mb-2">
                <div className="size-[100px] overflow-hidden rounded-full">
                  <img src={deposit?.picture} className="object-cover" />
                </div>
                <div className="flex flex-col">
                  <strong className="text-lg">{deposit?.user?.name}</strong>
                  <p className="text-custom-500">{deposit?._id}</p>
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-col w-1/2 pr-10 gap-1 pt-2">
                  <p>
                    <strong>Phone Number:</strong> {deposit.phone}
                  </p>
                  <p>
                    <strong>Nationality:</strong> {deposit.nationality}
                  </p>
                  <p>
                    <strong>Date of Birth:</strong>{" "}
                    {toReadableDate(deposit.dateOfBirth)}
                  </p>
                  <p>
                    <strong>Assigned Vehicle:</strong>{" "}
                    {deposit.assignedVehicle?.make}
                    {", "}
                    {deposit.assignedVehicle?.model}
                  </p>
                  <p>
                    <strong>Assigned Vehicles History:</strong>{" "}
                    {deposit.assignedVehiclesHistory?.map((
                      vehicle: any,
                      index: any,
                    ) => (
                      <div className="flex flex-row justify-between gap-5">
                        <p>
                          {++index}. {vehicle.vehicle?.make},{" "}
                          {vehicle.vehicle?.model}
                        </p>
                        <p className="opacity-80">
                          {toReadableDate(vehicle.startDate)}
                        </p>
                      </div>
                    ))}
                  </p>
                </div>
                <div className="flex flex-col w-2/3 gap-1 border-l border-l-custom-100 pl-10">
                  <div className="  flex flex-row justify-between w-full border-b border-b-custom-100 py-1">
                    <p>
                      <strong>Traffic File Number:</strong>
                      {deposit.trafficFileNumber}
                    </p>
                    <p>
                      <strong>File Number:</strong> {deposit.fileNumber}
                    </p>
                  </div>
                  <div className="  flex flex-row justify-between w-full border-b border-b-custom-100 py-1">
                    <p>
                      <strong>EID Number:</strong> {deposit.eidNumber}
                    </p>
                    <p>
                      <strong>EID Expiry:</strong>{" "}
                      {toReadableDate(deposit.eidExpiryDate)}
                    </p>
                  </div>

                  <div className="  flex flex-row justify-between w-full border-b border-b-custom-100 py-1">
                    <p>
                      <strong>Passport Number:</strong> {deposit.passportNumber}
                    </p>
                    <p>
                      <strong>Passport Expiry:</strong>{" "}
                      {toReadableDate(deposit.passportExpiryDate)}
                    </p>
                  </div>

                  <div className="  flex flex-row justify-between w-full border-b border-b-custom-100 py-1">
                    <p>
                      <strong>RTA Permit Number:</strong>{" "}
                      {deposit.rtaPermitNumber}
                    </p>
                    <p>
                      <strong>RTA Card Expiry:</strong>{" "}
                      {toReadableDate(deposit.rtaCardExpiryDate)}
                    </p>
                  </div>
                  <div className="  flex flex-row justify-between w-full border-b border-b-custom-100 py-1">
                    <p>
                      <strong>License Expiry:</strong>{" "}
                      {toReadableDate(deposit.licenseDetails?.expiryDate)}
                    </p>

                    <p>
                      <strong>License Number:</strong>{" "}
                      {deposit.licenseDetails?.number}
                    </p>
                  </div>
                  <p>
                    <strong>Visa Expiry:</strong>{" "}
                    {toReadableDate(deposit.visaExpiryDate)}
                  </p>

                  <p>
                    <strong>Joined On:</strong>{" "}
                    {toReadableDate(deposit.joiningDate)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Driver;
