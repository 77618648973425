import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import CountUp from "react-countup";
import { toast } from "react-hot-toast";
import { useAuthStore } from "store/useAuthStore";
import * as XLSX from "xlsx";
// icons
import {
  ArrowDown,
  CircleDollarSign,
  Download,
  Edit,
  Loader,
  MoreHorizontal,
  Printer,
  Search,
  Trash,
} from "lucide-react";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";

import { Dropdown } from "Common/Components/Dropdown";
import Modal from "Common/Components/Modal";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import moment from "moment";
import { approvalStatus } from "Common/constants/approval";
import PhotosUploader from "components/Forms/ImageUploader";

const Expenses = () => {
  const { user } = useAuthStore();
  const [creatingSale, setCreatingSale] = useState<boolean>(false);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [carList, setCarList] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [initialDriver, setInitialDriver] = useState<string>("");
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");
  const [images, setImages] = useState<any>([]);
  const [creator, setCreator] = useState<string>("office");

  useEffect(() => {
    if (images.length > 0) {
      validation.setFieldValue("expenseImage", images[0]);
    } else {
      validation.setFieldValue("expenseImage", "");
    }
  }, [images]);

  useEffect(() => {
    handleGetCars();
    handleFetchUsers();
    handleGetAllExpenses();
  }, []);

  const handleGetAllExpenses = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/expense/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setDataList(res.data);
      setData(res.data);
      // set null approvedBy fields to N/A
      setData((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            approvedBy: item.approvedBy ? item.approvedBy : { name: "N/A" },
          };
        });
      });
      setDataList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            approvedBy: item.approvedBy ? item.approvedBy : { name: "N/A" },
          };
        });
      });
      // format dates to a readable format
      setData((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            expenseDate: moment(item.expenseDate).format("DD MMMM, YYYY"),
          };
        });
      });

      setData((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            approvedByName: item.approvedBy.name,
          };
        });
      });

      setDataList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            approvedByName: item.approvedBy.name,
          };
        });
      });

      setData((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            driverName: item.driver?.user ? item.driver?.user?.name : "N/A",
          };
        });
      });

      setDataList((prev: any) => {
        return prev.map((item: any) => {
          console.log(item);
          return {
            ...item,
            driverName: item.driver?.user ? item.driver?.user?.name : "N/A",
          };
        });
      });
      setDataList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            expenseDate: moment(item.expenseDate).format("DD MMMM, YYYY"),
          };
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetCars = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/car/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setCarList(res.data);
      setCarList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            createdAt: moment(item.createdAt).format("DD MMMM, YYYY"),
          };
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchUsers = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/driver/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setInitialDriver(res.data[0]._id);
      setUserList(res.data);
      setUserList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            createdAt: moment(item.createdAt).format("DD MMMM, YYYY"),
          };
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteExpense = async (id: string) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URI}/crm/expense/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      handleGetAllExpenses();
      toast.success("Expense deleted successfully!");
    } catch (error) {
      console.log(error);
    }
  };

  // write a function that filters data based on a day, week, month, year string received in arguments
  // and returns the filtered data
  const filterData = (time: string) => {
    const today = new Date();
    const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const lastMonth = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    const lastYear = new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000);
    switch (time) {
      case "day": {
        setData(data.filter((item: any) => {
          return new Date(item.expenseDate) >= today;
        }));

        break;
      }
      case "week":
        setData(data.filter((item: any) => {
          return new Date(item.expenseDate) >= lastWeek;
        }));
        break;
      case "month":
        setData(data.filter((item: any) => {
          return new Date(item.expenseDate) >= lastMonth;
        }));
        break;
      case "year":
        setData(data.filter((item: any) => {
          return new Date(item.expenseDate) >= lastYear;
        }));
        break;
      default:
        setData(dataList);
    }
  };

  const filterDate: any = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object({
      toDate: Yup.string().required("Please Enter To Date"),
      fromDate: Yup.string().required("Please Enter From Date"),
    }),

    onSubmit: async (values) => {
      const { fromDate, toDate } = values;
      console.log(fromDate, toDate);
    },
  });

  function handlePrint() {
    window.print();
  }

  const handleFilterDate = () => {
    const { fromDate, toDate } = filterDate.values;
    if (!fromDate || !toDate) {
      return toast.error("Please select both from and to date");
    }
    const from = new Date(fromDate);
    const to = new Date(toDate);
    if (from > to) {
      return toast.error("From date cannot be greater than to date");
    }
    setData(dataList.filter((item: any) => {
      return new Date(item.expenseDate) >= from &&
        new Date(item.expenseDate) <= to;
    }));
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      expenseDate: "",
      expenseType: "",
      // driver: initialDriver,
      amount: null,
      description: "",
      approvalStatus: approvalStatus[0].name,
      receipt: "",
      expenseImage: "",
      service: "n/a",
      expenseBy: "",
    },
    validationSchema: Yup.object({
      expenseDate: Yup.string().required("Please enter expense date"),
      expenseType: Yup.string().required("Please enter expense type"),
      service: Yup.string().required("Please select service"),
      // driver: Yup.string().optional(),
      amount: Yup.number().required("Please enter amount"),
      receipt: Yup.string(),
      description: Yup.string(),
      approvalStatus: Yup.string().required("Please input status"),
      expenseImage: Yup.string().required("Please upload expense image"),
      expenseBy: Yup.string().required("Please select expense creator"),
    }),

    onSubmit: async (values) => {
      const newData = {
        ...values,
      };
      setCreatingSale(true);
      if (showEdit) {
        try {
          await axios.put(
            `${process.env.REACT_APP_BASE_URI}/crm/expense/${editId}`,
            {
              ...newData,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          );
          handleGetAllExpenses();
          toast.success("Expense updated successfully!");
          toggleEdit({});
          validation.resetForm();
        } catch (error: any) {
          if (!error.response) {
            return toast.error("Network error. Please try again.");
          }
          if (typeof error.response.data === "string") {
            return toast.error(error.response.data);
          }
        } finally {
          setCreatingSale(false);
        }
      } else {
        try {
          await axios.post(
            `${process.env.REACT_APP_BASE_URI}/crm/expense`,
            {
              ...newData,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          );
          handleGetAllExpenses();
          toast.success("Expense made successfully!");
          toggle();
          validation.resetForm();
        } catch (error: any) {
          if (!error.response) {
            return toast.error("Network error. Please try again.");
          }
          if (typeof error.response.data === "string") {
            return toast.error(error.response.data);
          }
        } finally {
          setCreatingSale(false);
        }
      }
    },
  });

  const toggleEdit = useCallback((expense: any) => {
    if (showEdit) {
      setShowEdit(false);
      setEditId("");
    } else {
      setShowEdit(true);
      setEditId(expense._id);
      validation.setFieldValue("amount", expense.amount);
      validation.setFieldValue("approvalStatus", expense.approvalStatus);
      validation.setFieldValue("expenseType", expense.expenseType);
      // validation.setFieldValue("driver", expense.driver._id);
      validation.setFieldValue("expenseDate", expense.expenseDate);
      validation.setFieldValue("service", expense.service);
      validation.setFieldValue("description", expense.description);
      validation.setFieldValue("receipt", expense.receipt);

      // validation.resetForm();
    }
  }, [showEdit, validation]);

  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
      validation.resetForm();
    }
  }, [show, validation]);

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = [
      "_id",
      "amount",
      "driverName",
      "approvedByName",
      "expenseType",
      "expenseDate",
    ];
    const searchResult = dataList.filter((item: any) =>
      keysToSearch.some((key) =>
        item[key].toString().toLowerCase().includes(search.toLowerCase())
      )
    );
    setData(searchResult);
  };

  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    setDataList(data);
  }, []);

  const toggleTab = (tab: any, type: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredDeposits = dataList;
      if (type !== "all") {
        filteredDeposits = dataList.filter(
          (expense: any) => expense.approvalStatus === type,
        );
      }
      setData(filteredDeposits);
    }
  };

  // columns
  const Status = ({ item }: any) => {
    switch (item) {
      case "Paid":
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">
            {item}
          </span>
        );
      case "Pending":
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-yellow-100 border-yellow-200 text-yellow-500 dark:bg-yellow-500/20 dark:border-yellow-500/20">
            {item}
          </span>
        );
      case "Not Paid":
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-red-100 border-red-200 text-red-500 dark:bg-red-500/20 dark:border-red-500/20">
            {item}
          </span>
        );
      default:
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">
            {item}
          </span>
        );
    }
  };

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id={"Checkbox" + cell.row.original.id}
                className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: "ID",
        accessorKey: "_id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <>
            <Link
              to="#!"
              className="transition-all duration-150 ease-linear order_id text-custom-500 hover:text-custom-600"
            >
              {cell.getValue()}
            </Link>
          </>
        ),
      },
      {
        header: "Receipt",
        accessorKey: "receipt",
        enableColumnFilter: false,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
      },
      {
        header: "Approval Status",
        accessorKey: "approvalStatus",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },
      {
        header: "Expense Type",
        accessorKey: "expenseType",
        enableColumnFilter: false,
      },
      // {
      //   header: "Driver",
      //   accessorKey: "driver.user.name",
      //   enableColumnFilter: false,
      // },
      {
        header: "Expense Date",
        accessorKey: "expenseDate",
        enableColumnFilter: false,
      },
      {
        header: "Approved By",
        accessorKey: "approvedBy.name",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              id="orderAction1"
              data-bs-toggle="dropdown"
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement={cell.row.index ? "top-end" : "right-end"}
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="orderAction1"
            >
              <li
                onClick={() => toggleEdit(cell.row.original)}
                className="cursor-pointer"
              >
                <div className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  <Edit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                  {" "}
                  <span className="align-middle">Update status</span>
                </div>
              </li>
              <li
                onClick={() => deleteExpense(cell.row.original._id)}
              >
                <p className="block cursor-pointer px-4 py-1.5 text-base transition-all duration-200 ease-linear text-red-500 hover:bg-red-100 hover:text-red-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  <Trash className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                  {" "}
                  <span className="align-middle">Delete</span>
                </p>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    [],
  );

  const downloadExpensesInExcel = async () => {
    console.log(dataList);
    const data = dataList.map((item: any) => {
      return {
        ID: item._id,
        Amount: item.amount,
        ApprovalStatus: item.approvalStatus,
        ExpenseType: item.expenseType,
        Driver: item.driver.user.name,
        DriverId: item.driver._id,
        ExpenseDate: item.expenseDate,
        ApprovedBy: item.approvedBy.name,
      };
    });
    console.log(data);
    const fileName = "Expenses";
    const exportType = "xls";
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.${exportType}`);
  };

  return (
    <React.Fragment>
      <BreadCrumb title="All Expenses" pageTitle="Expenses" />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 2xl:grid-cols-10">
        {
          /* <div className="2xl:col-span-2 2xl:row-span-1">
          <div className="card">
            <div className="flex items-center gap-3 card-body">
              <div className="flex items-center justify-center size-12 rounded-md text-15 bg-custom-50 text-custom-500 dark:bg-custom-500/20 shrink-0">
                <CircleDollarSign />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    end={data.length}
                    separator=","
                    className="counter-value"
                  />
                </h5>
                <p className="text-slate-500 dark:text-zink-200">
                  Total Expenses
                </p>
              </div>
            </div>
          </div>
        </div> */
        }
        <div className="2xl:col-span-2 2xl:row-span-1">
          <div className="card">
            <div className="flex items-center gap-3 card-body">
              <div className="flex items-center justify-center size-12 text-yellow-500 rounded-md text-15 bg-yellow-50 dark:bg-yellow-500/20 shrink-0">
                <Loader />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    end={data.filter((item: any) =>
                      item.approvalStatus == "Not Paid"
                    ).reduce(
                      (acc: any, sale: any) => acc + sale.amount,
                      0,
                    )}
                    separator=","
                    className="counter-value"
                  />
                </h5>
                <p className="text-slate-500 dark:text-zink-200">
                  Total Not Paid
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="2xl:col-span-2 2xl:row-span-1">
          <div className="card">
            <div className="flex items-center gap-3 card-body">
              <div className="flex items-center justify-center size-12 text-green-500 rounded-md text-15 bg-green-50 dark:bg-green-500/20 shrink-0">
                <ArrowDown />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    end={data.filter((item: any) =>
                      item.approvalStatus == "Paid"
                    ).reduce(
                      (acc: any, sale: any) => acc + sale.amount,
                      0,
                    )}
                    separator=","
                    className="counter-value"
                  />
                </h5>
                <p className="text-slate-500 dark:text-zink-200">
                  Total Paid
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card" id="ordersTable">
        <div className="card-body">
          <div className="flex gap-2">
            <div className="ml-auto flex flex-row gap-2">
              <button
                onClick={handlePrint}
                type="button"
                className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
              >
                <Printer className="inline-block size-4" />{" "}
                <span className="align-middle">Print page</span>
              </button>
              <button
                onClick={downloadExpensesInExcel}
                type="button"
                className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
              >
                <Download className="inline-block size-4" />{" "}
                <span className="align-middle">Export</span>
              </button>
            </div>
          </div>
          <div className="flex items-center">
            <h6 className="text-15 grow">Expenses List</h6>
          </div>
          <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
            <form action="#!">
              <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                <div className="relative xl:col-span-2">
                  <input
                    type="text"
                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Search for name, email, phone number etc..."
                    autoComplete="off"
                    onChange={(e) => filterSearchData(e)}
                  />
                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                </div>
                <div className="xl:col-span-2">
                  <select
                    className="form-select text-slate-400 border-slate-200 dark:border-zink-500 dark:text-zink-200"
                    onChange={(e) => filterData(e.target.value)}
                  >
                    <option value="all" selected>All time</option>
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                    <option value="year">Year</option>
                  </select>
                </div>

                <div className="xl:col-span-6 text-slate-400 flex flex-row gap-4 w-full">
                  <div className="w-1/2 flex flex-row gap-2 items-center">
                    <label
                      htmlFor="fromDate"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      From
                    </label>
                    <input
                      type="date"
                      id="fromDate"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="12345678"
                      name="fromDate"
                      onChange={filterDate.handleChange}
                      value={filterDate.values.fromDate || ""}
                    />
                  </div>

                  <div className="w-1/2 flex flex-row gap-2 items-center">
                    <label
                      htmlFor="toDate"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      To
                    </label>
                    <input
                      type="date"
                      id="toDate"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="12345678"
                      name="toDate"
                      onChange={filterDate.handleChange}
                      value={filterDate.values.toDate || ""}
                    />
                  </div>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleFilterDate();
                    }}
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  >
                    {"Filter "}
                  </button>
                </div>
                <div className="xl:col-span-3 xl:col-start-10">
                  <div className="flex gap-2 xl:justify-end">
                    <div>
                      <button
                        type="button"
                        className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                        onClick={toggle}
                      >
                        <span className="align-middle">Add expense</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <ul className="flex flex-wrap w-full mt-5 text-sm font-medium text-center text-gray-500 nav-tabs">
            <li className={`group ${activeTab === "1" && "active"}`}>
              <Link
                to="#"
                data-tab-toggle
                data-target=""
                className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                onClick={() => {
                  toggleTab("1", "all");
                }}
              >
                <CircleDollarSign className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
                {" "}
                <span className="align-middle">All Expenses</span>
              </Link>
            </li>
            <li className={`group ${activeTab === "2" && "active"}`}>
              <Link
                to="#"
                data-tab-toggle
                data-target="all"
                className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                onClick={() => {
                  toggleTab("2", "Paid");
                }}
              >
                <Loader className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
                {" "}
                <span className="align-middle">Paid</span>
              </Link>
            </li>
            <li className={`group ${activeTab === "3" && "active"}`}>
              <Link
                to="#"
                data-tab-toggle
                data-target=""
                className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                onClick={() => {
                  toggleTab("3", "Not Paid");
                }}
              >
                <ArrowDown className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
                {" "}
                <span className="align-middle">Not Paid</span>
              </Link>
            </li>
          </ul>

          {data && data.length > 0
            ? (
              <TableContainer
                isPagination={true}
                columns={columns || []}
                data={data || []}
                customPageSize={10}
                divclassName="mt-5 overflow-x-auto"
                tableclassName="w-full whitespace-nowrap"
                theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                thclassName="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:border-zink-500 dark:text-zink-200"
                tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              />
            )
            : (
              <div className="noresult">
                <div className="py-6 text-center">
                  <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                  <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                  <p className="mb-0 text-slate-500 dark:text-zink-200">
                    We've searched all expenses, but we did not find any
                    expenses for your search.
                  </p>
                </div>
              </div>
            )}
        </div>
      </div>

      {/* // write a modal that updates the status of the expense */}

      {/* Order Modal */}

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">{"Create an Expense"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-12">
                <label
                  htmlFor="amount"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Amount
                </label>
                <input
                  type="text"
                  id="amount"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="12345678"
                  name="amount"
                  onChange={validation.handleChange}
                  value={validation.values.amount || ""}
                />
                {validation.touched.amount && validation.errors.amount
                  ? <p className="text-red-400">{validation.errors.amount}</p>
                  : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="expenseType"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Expense Type
                </label>
                <input
                  type="text"
                  id="expenseType"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Vehicle Repair"
                  name="expenseType"
                  onChange={validation.handleChange}
                  value={validation.values.expenseType || ""}
                />
                {validation.touched.expenseType && validation.errors.expenseType
                  ? (
                    <p className="text-red-400">
                      {validation.errors.expenseType}
                    </p>
                  )
                  : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="expenseDate"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Date
                </label>
                <input
                  type="date"
                  id="expenseDate"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="12345678"
                  name="expenseDate"
                  onChange={validation.handleChange}
                  value={validation.values.expenseDate || ""}
                />
                {validation.touched.expenseDate && validation.errors.expenseDate
                  ? (
                    <p className="text-red-400">
                      {validation.errors.expenseDate}
                    </p>
                  )
                  : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="expenseBy"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Expense By
                </label>

                <input
                  type="text"
                  id="expenseBy"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Office"
                  name="expenseBy"
                  onChange={validation.handleChange}
                  value={validation.values.expenseBy || ""}
                />
                {
                  /* <select
                  name="expenseBy"
                  id="expenseBy"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.expenseBy || ""}
                  onChange={(e) => {
                    validation.handleChange(e);
                    setCreator(e.target.value);
                  }}
                  required
                >
                  <option value={"office"}>
                    Office
                  </option>
                  <option value={"driver"}>
                    Driver
                  </option>
                </select> */
                }
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="service"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Service
                </label>
                <select
                  name="service"
                  id="service"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.service || ""}
                  onChange={validation.handleChange}
                  required
                >
                  <option value={"n/a"}>
                    N/A
                  </option>
                  <option value={"uber"}>
                    Uber
                  </option>
                  <option value={"yango"}>
                    Yango
                  </option>
                  <option value={"careem"}>
                    Careem
                  </option>
                  <option value={"posMachine"}>
                    POS Machine
                  </option>
                  <option value={"other"}>
                    Other sources
                  </option>
                </select>
              </div>

              {validation.values.expenseBy == "driver" &&
                (
                  <div className="xl:col-span-12">
                    <label
                      htmlFor="driver"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Driver
                    </label>
                    <select
                      name="driver"
                      id="driver"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      value={validation.values.driver || ""}
                      onChange={validation.handleChange}
                      required
                    >
                      {userList.map((driver) => // @ts-ignore
                      <option value={driver._id}>{driver.user.name}</option>)}
                    </select>
                  </div>
                )}
              <div className="xl:col-span-12">
                <label
                  htmlFor="approvalStatus"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Status
                </label>
                <select
                  name="approvalStatus"
                  id="approvalStatus"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.approvalStatus || ""}
                  onChange={validation.handleChange}
                  required
                >
                  {approvalStatus.map((status: any, index: any) => (
                    <option value={status.name} key={index}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="receipt"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Receipt
                </label>
                <input
                  type="text"
                  id="receipt"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="12345678"
                  name="receipt"
                  onChange={validation.handleChange}
                  value={validation.values.receipt || ""}
                />
                {validation.touched.receipt && validation.errors.receipt
                  ? <p className="text-red-400">{validation.errors.receipt}</p>
                  : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="description"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Description <span className="opacity-50">(optional)</span>
                </label>
                <textarea
                  id="description"
                  className="form-input resize-none border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Leave a message"
                  name="description"
                  onChange={validation.handleChange}
                  value={validation.values.description || ""}
                />
              </div>
            </div>
            <div className="xl:col-span-12">
              <label
                htmlFor="expenseImage"
                className="inline-block mb-2 text-base font-medium"
              >
                Upload image
              </label>
              <PhotosUploader
                maxPhotos={1}
                addedPhotos={images}
                onChange={(photos: any) => {
                  setImages(photos);
                }}
              />
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {"Create Expense"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEdit}
        onHide={toggleEdit}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">{"Update Status"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-12">
                <label
                  htmlFor="approvalStatus"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Status
                </label>
                <select
                  name="approvalStatus"
                  id="approvalStatus"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.approvalStatus || ""}
                  onChange={validation.handleChange}
                  required
                >
                  {approvalStatus.map((status: any, index: any) => (
                    <option value={status.name} key={index}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggleEdit}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {"Update Expense"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Expenses;
