export const approvalStatus = [
  {
    id: 1,
    name: "Not Paid",
  },
  {
    id: 2,
    name: "Paid",
  },
];
