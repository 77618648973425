import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function toReadableDate(date: string | Date) {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
}

export function capitalizeFirstLetter(text: string | undefined) {
  return text?.charAt(0).toUpperCase() + text!.slice(1);
}

export function toStringTime(date: Date) {
  const hours = date?.getHours().toString().padStart(2, "0");
  const min = date?.getMinutes().toString().padStart(2, "0");
  const sec = date?.getSeconds().toString().padStart(2, "0");

  const timeString = `${hours}:${min}:${sec}`;

  return timeString;
}

export function capitalizeAndReplace(str: string) {
  return str.replace(/(?:^|-)\w/g, function(match: any) {
    return match.toUpperCase();
  }).replace(/-/g, " ");
}

export function isDateWithinNext20Days(date: Date) {
  const dateToCheck = new Date(date);
  const today = new Date();
  const twentyDaysFromNow = new Date();
  twentyDaysFromNow.setDate(today.getDate() + 20);

  if (dateToCheck >= today && dateToCheck <= twentyDaysFromNow) {
    return true;
  } else {
    return false;
  }
}

export function hasDatePassed(date: Date) {
  const dateToCheck = new Date(date);
  const today = new Date();

  if (dateToCheck <= today) {
    return true;
  } else {
    return false;
  }
}

export const formatDateToInput = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export function parseDateToReadableFormat(date: Date) {
  // Ensure the input is a Date object
  const parsedDate = new Date(date);

  // Define options for readable date format
  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  // @ts-ignore
  const readableDate = parsedDate.toLocaleDateString('en-US', dateOptions);

  // Define options for readable time format
  const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
  // @ts-ignore
  const readableTime = parsedDate.toLocaleTimeString('en-US', timeOptions);

  return { readableDate, readableTime };
}
