import { ReactNode } from "react";
import { Link } from "react-router-dom";
const AlertCard = (
  { icon, content, linkTitle, link, priority }: {
    icon: ReactNode;
    content: string;
    linkTitle: string;
    link: string;
    priority?: "high" | "medium" | "low";
  },
) => {
  return (
    <div
      className={`flex flex-row ${
        priority == "high"
          ? "bg-red-50"
          : priority == "medium"
          ? "bg-yellow-50"
          : "bg-white"
      } items-center gap-4 p-6 border ${
        priority == "high"
          ? "border-red-200"
          : priority == "medium"
          ? "border-yellow-200"
          : "border-gray-200"
      } rounded-md `}
    >
      <div
        className={`${
          priority == "high"
            ? "text-red-500"
            : priority == "medium"
            ? "text-yellow-600"
            : "text-gray-700"
        }`}
      >
        {icon}
      </div>
      <div
        className={`${
          priority == "high"
            ? "text-red-500"
            : priority == "medium"
            ? "text-yellow-600"
            : "text-gray-700"
        }`}
      >
        {content}
      </div>
      <Link
        to={link}
        className={`${
          priority == "high"
            ? "text-red-500 "
            : priority == "medium"
            ? "text-yellow-700"
            : "text-gray-700"
        } 
          ${
          priority == "high"
            ? "bg-red-100 "
            : priority == "medium"
            ? "bg-yellow-100"
            : "bg-gray-100"
        }
        rounded-lg p-1 px-3 
          ${
          priority == "high"
            ? "hover:bg-red-200 "
            : priority == "medium"
            ? "hover:bg-yellow-200"
            : "hover:bg-gray-200"
        }
        transition-all`}
      >
        {linkTitle}
      </Link>
    </div>
  );
};

export default AlertCard;
