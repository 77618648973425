import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import BreadCrumb from "Common/BreadCrumb";
import { toast } from "react-hot-toast";
// icons
import { Download } from "lucide-react";
import { BlobProvider, PDFDownloadLink, Text } from "@react-pdf/renderer";

import { ToastContainer } from "react-toastify";
import axios from "axios";
import moment from "moment";
import ReportPdf from "components/ReportPdf/ReportPdf";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import CarReportPdf from "components/ReportPdf/CarReportPdf";
// @ts-ignore
import { DateRangePicker } from 'react-date-range';
import { enUS } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file


const CarReport = () => {
  const [vehiclesList, setVehiclesList] = useState([]);
  const [dataList, setDataList] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [report, setReport] = useState<any>(null);
  const [originalReport, setOriginalReport] = useState<any>(null);
  const [selectedDriver, setSelectedDriver] = useState<boolean>(false);
  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection"
  })

  const downloadPDF = () => {
    const input: HTMLElement | null = document.getElementById("report-pdf");
    const scale = 3; // Increase the scale
    html2canvas(input!, { scale }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdfWidth = 210; // A4 width in mm
      const pdfHeight = 297; // A4 height in mm

      const aspectRatio = canvas.width / canvas.height;

      let width = pdfWidth;
      let height = pdfWidth / aspectRatio;

      if (height > pdfHeight) {
        height = pdfHeight;
        width = pdfHeight * aspectRatio;
      }

      const pdf = new jsPDF({
        orientation: height > width ? "portrait" : "landscape",
        unit: "mm",
        format: "a4",
      });
      const x = (pdfWidth - width) / 2;
      const y = (pdfHeight - height) / 2;

      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.save("download.pdf");
    });
  };

  function filterDataForLastMonth(report: any) {
    const currentDate = new Date();
    const lastMonthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      currentDate.getDate(),
    ); // Calculate date for one month ago

    const filteredReport = { ...report }; // Create a copy of the original report to avoid mutating it

    // Filter renewals based on renewalDate
    filteredReport.renewals = report.renewals.filter(
      (renewal: any) => new Date(renewal.renewalDate) > lastMonthDate,
    );

    // Filter expenses based on expenseDate
    filteredReport.expenses = report.expenses.filter(
      (expense: any) => new Date(expense.expenseDate) > lastMonthDate,
    );

    // Filter sales based on saleDate
    filteredReport.sales = report.sales.filter(
      (sale: any) => new Date(sale.saleDate) > lastMonthDate,
    );

    return filteredReport;
  }

  function filterDataForLastYear(report: any) {
    const currentDate = new Date();
    const lastYearDate = new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      currentDate.getDate(),
    ); // Calculate date for one year ago

    const filteredReport = { ...report }; // Create a copy of the original report to avoid mutating it

    // Filter renewals based on renewalDate
    filteredReport.renewals = report.renewals.filter(
      (renewal: any) => new Date(renewal.renewalDate) > lastYearDate,
    );

    // Filter expenses based on expenseDate
    filteredReport.expenses = report.expenses.filter(
      (expense: any) => new Date(expense.expenseDate) > lastYearDate,
    );

    // Filter sales based on saleDate
    filteredReport.sales = report.sales.filter(
      (sale: any) => new Date(sale.saleDate) > lastYearDate,
    );

    return filteredReport;
  }

  function filterDataForToday(report: any) {
    const currentDate = new Date();
    const todayDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
    ); // Calculate date for today

    const filteredReport = { ...report }; // Create a copy of the original report to avoid mutating it

    // Filter renewals based on renewalDate
    filteredReport.renewals = report.renewals.filter(
      (renewal: any) => new Date(renewal.renewalDate) > todayDate,
    );

    // Filter expenses based on expenseDate
    filteredReport.expenses = report.expenses.filter(
      (expense: any) => new Date(expense.expenseDate) > todayDate,
    );

    // Filter sales based on saleDate
    filteredReport.sales = report.sales.filter(
      (sale: any) => new Date(sale.saleDate) > todayDate,
    );

    return filteredReport;
  }

  function filterDataForLastWeek(report: any) {
    const currentDate = new Date();
    const lastWeekDate = new Date(
      currentDate.getTime() - 7 * 24 * 60 * 60 * 1000,
    ); // Calculate date for one week ago

    const filteredReport = { ...report }; // Create a copy of the original report to avoid mutating it

    // Filter renewals based on renewalDate
    filteredReport.renewals = report.renewals.filter(
      (renewal: any) => new Date(renewal.renewalDate) > lastWeekDate,
    );

    // Filter expenses based on expenseDate
    filteredReport.expenses = report.expenses.filter(
      (expense: any) => new Date(expense.expenseDate) > lastWeekDate,
    );

    // Filter sales based on saleDate
    filteredReport.sales = report.sales.filter(
      (sale: any) => new Date(sale.saleDate) > lastWeekDate,
    );

    return filteredReport;
  }

  const getVehicleReport = async (id: string) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/report/car/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      toast.success("Driver report fetched successfully!");
      setReport(res.data);
      console.log(res.data);
      setOriginalReport(res.data);
    } catch (error) {
      toast.error("Failed to fetch driver report!");
      console.log(error);
    }
  };

  const handleFetchVehicles = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/car/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setVehiclesList(res.data);
      setVehiclesList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            createdAt: moment(item.createdAt).format("DD MMMM, YYYY"),
          };
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  function filterReportDataByRange(startDate: Date, endDate: Date): void {

    if(!selectedDriver){
      toast.error("Please select a vehicle to filter the report data");
      return;
    }
    const filteredReport = { ...originalReport }; // Create a copy of the original report to avoid mutating it

    // Filter renewals based on renewalDate
    filteredReport.renewals = originalReport.renewals?.filter(
      (renewal: any) => new Date(renewal.renewalDate) >= startDate && new Date(renewal.renewalDate) <= endDate,
    );

    // Filter expenses based on expenseDate
    filteredReport.expenses = originalReport.expenses?.filter(
      (expense: any) => new Date(expense.expenseDate) >= startDate && new Date(expense.expenseDate) <= endDate,
    );

    // Filter sales based on saleDate
    filteredReport.sales = originalReport.sales?.filter(
      (sale: any) => new Date(sale.saleDate) >= startDate && new Date(sale.saleDate) <= endDate,
    );
    console.log("after filtering: ", filteredReport);
    setReport(filteredReport);
  };

  useEffect(() => {
    handleFetchVehicles();
  }, []);

  useEffect(() => {
    setDataList(data);
  }, []);

  return (
    <React.Fragment>
      <BreadCrumb title="Vehicle Reports" pageTitle="Management" />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="Drivers">
            <div className="card-body">
              <div className="flex flex-row relative items-center justify-between">
                <div className="flex items-center">
                  <h6 className="text-15 grow">Vehicle Report</h6>
                </div>

                <button
                  onClick={() => setShowDateRange(!showDateRange)}
                  type="button"
                  className="bg-white mt-4 border-dashed w-1/6 text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                >
                  <span className="align-middle">Filter</span>
                </button>
                {

                  showDateRange &&
                  <div className="absolute right-0 top-16 z-[9999]">
                    <DateRangePicker
                      className="shadow-md rounded-md"
                      ranges={[dateRange]}
                      onChange={(item) => {
                        setDateRange(item.selection);
                        filterReportDataByRange(item.selection.startDate ?? new Date(), item.selection.endDate ?? new Date());
                      }}
                      locale={enUS}
                    />
                  </div>

                }
              </div>
              <div className=" w-full flex md:flex-row flex-col gap-5 mt-2">
                <div className="lg:w-2/3 w-full" id="report-pdf">
                  <CarReportPdf report={report} />
                </div>
                {
                  /* <div className="w-1/2 flex flex-col gap-4">
                  {report &&
                    (
                      <>
                        <div className="flex-row flex gap-3 items-center ">
                          <div className="flex items-center text-lg justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
                            {report.user?.name[0]}
                          </div>
                          <div className="flex flex-col">
                            <p className="text-lg">{report.user?.name}</p>
                            <p className="text-sm opacity-60">{report._id}</p>
                          </div>
                        </div>
                        <div className="text-lg flex flex-row w-11/12 border-b justify-between px-4 pb-1 border-custom-100">
                          <p className="font-semibold">
                            Assigned Vehicle:
                          </p>
                          <p>
                            {report.assignedVehicle == "N/A"
                              ? "N/A"
                              : report.assignedVehicle.make + ", " +
                                report.assignedVehicle.model}
                          </p>
                        </div>
                        <div className="text-lg flex flex-row border-b w-11/12 justify-between px-4 pb-1 border-custom-100">
                          <p className="font-semibold">
                            Registration Number:
                          </p>
                          <p>
                            {report.assignedVehicle == "N/A"
                              ? "N/A"
                              : report.assignedVehicle.registrationNumber}
                          </p>
                        </div>
                        <div className="text-lg flex flex-row border-b w-11/12 justify-between px-4 pb-1 border-custom-100">
                          <p className="font-semibold">
                            Joined At:
                          </p>
                          <p>
                            {toReadableDate(report.createdAt)}
                          </p>
                        </div>
                        <div className="text-lg flex flex-row border-b w-11/12 justify-between px-4 pb-1 border-custom-100">
                          <p className="font-semibold">
                            Total Renewals:
                          </p>
                          <p>
                            {report.renewals.length}
                          </p>
                        </div>
                        <div className="text-lg flex flex-row border-b w-11/12 justify-between px-4 pb-1 border-custom-100">
                          <p className="font-semibold">
                            Total Sales:
                          </p>
                          <p>
                            {report.sales.length}
                          </p>
                        </div>
                        <div className="text-lg flex flex-row border-b w-11/12 justify-between px-4 pb-1 border-custom-100">
                          <p className="font-semibold">
                            Total Expenses:
                          </p>
                          <p>
                            {report.expenses.length}
                          </p>
                        </div>
                        <div className="text-lg flex flex-row border-b w-11/12 justify-between px-4 pb-1 border-custom-100">
                          <p className="font-semibold">
                            Earned from sales:
                          </p>
                          <p>
                            AED {report.sales.reduce((acc: any, item: any) =>
                              acc + item.saleAmount, 0)}
                          </p>
                        </div>
                        <div className="text-lg flex flex-row border-b w-11/12 justify-between px-4 pb-1 border-custom-100">
                          <p className="font-semibold">
                            Total amount spent:
                          </p>
                          <p>
                            AED {report.expenses.reduce((acc: any, item: any) =>
                              acc + item.amount, 0)}
                          </p>
                        </div>
                      </>
                    )}
                </div> */
                }
                <div className="lg:w-1/3 w-full">
                  <h5>Select Driver</h5>
                  <select
                    onChange={(e) => {
                      getVehicleReport(e.target.value);
                      setSelectedDriver(true);
                    }}
                    className="border w-full dark:bg-transparent mt-2 p-1 rounded-md text-lg px-2"
                  >
                    <option value="" disabled selected>Select a vehicle</option>
                    {vehiclesList.map((item: any) => {
                      return (
                        <option value={item._id}>
                          {item.make}, {item.model}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    type="button"
                    className="bg-white mt-4 border-dashed w-full text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                    onClick={downloadPDF}
                    disabled={selectedDriver ? false : true}
                  >
                    <Download className="inline-block size-4" />{" "}
                    <span className="align-middle">Export</span>
                  </button>

                  <button
                    type="button"
                    className="bg-white mt-4 border-dashed w-full text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                    onClick={() => setReport(originalReport)}
                    disabled={selectedDriver ? false : true}
                  >
                    <span className="align-middle">Clear Filters</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CarReport;
