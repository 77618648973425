import AlertCard from "components/UIElement/UiCards/AlertCard";
import axios from "axios";
import { Book, CarFront, CreditCard, UserRoundCog } from "lucide-react";
import { useEffect, useState } from "react";

const Alert = () => {
  const [licenseCount, setLicenseCount] = useState<number>(0);
  const [passportCount, setPassportCount] = useState<number>(0);
  const [rtaCount, setRtaCount] = useState<number>(0);
  const [visaCount, setVisaCount] = useState<number>(0);
  const [eidCount, setEidCount] = useState<number>(0);
  const [leaveCount, setLeaveCount] = useState<number>(0);
  const [vehicleCount, setVehicleCount] = useState<number>(0);
  const [renewedVehicleCount, setRenewedVehicleCount] = useState<number>(0);
  const [renewedDriverCount, setRenewedDriverCount] = useState<number>(0);
  const [otherCount, setOtherCount] = useState<number>(0);
  const [totalOtherRenewals, setTotalOtherRenewals] = useState<number>(0);
  const [totalDriverRenewals, setTotalDriverRenewals] = useState<number>(0);
  const [totalVehicleRenewals, setTotalVehicleRenewals] = useState<number>(0);

  const fetchDriverExpiry = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/alert/driver`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setLicenseCount(response.data.licenseCount);
      setPassportCount(response.data.passportCount);
      setRtaCount(response.data.rtaCount);
      setVisaCount(response.data.visaCount);
      setEidCount(response.data.eidCount);
      setLeaveCount(response.data.leaveCount);
      setRenewedDriverCount(response.data.renewalCount);
      setTotalDriverRenewals(response.data.totalRenewalCount);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchVehicleExpiry = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/alert/vehicle`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setVehicleCount(response.data.vehicles);
      setRenewedVehicleCount(response.data.renewedVehicles);
      setTotalVehicleRenewals(response.data.totalRenewalCount);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOtherExpiry = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/alert/other`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      console.log(response.data);
      setOtherCount(response.data.others);
      setTotalOtherRenewals(response.data.totalRenewalCount);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDriverExpiry();
    fetchVehicleExpiry();
    fetchOtherExpiry();
  }, []);

  return (
    <div className="grid grid-cols-3 gap-x-5 gap-y-3">
      {/* {totalDriverRenewals != 0 &&
        (
          <AlertCard
            icon={<UserRoundCog size={25} className="" />}
            content={`You have ${
              totalDriverRenewals > 5 ? "5+" : totalDriverRenewals
            } ${
              totalDriverRenewals == 1 ? "driver" : "drivers"
            } whose renewals are pending.`}
            link="/renewals/drivers"
            linkTitle="View"
            priority="high"
          />
        )} */}

      {/* {totalVehicleRenewals != 0 &&
        (
          <AlertCard
            icon={<CarFront size={25} className="" />}
            content={`You have ${
              totalVehicleRenewals > 5 ? "5+" : totalVehicleRenewals
            } ${
              totalVehicleRenewals == 1 ? "vehicle" : "vehicles"
            } whose renewals are pending.`}
            link="/renewals/cars"
            linkTitle="View"
            priority="high"
          />
        )} */}

      {/* {totalOtherRenewals != 0 &&
        (
          <AlertCard
            icon={<CarFront size={25} className="" />}
            content={`You have ${
              totalOtherRenewals > 5 ? "5+" : totalOtherRenewals
            } ${
              totalOtherRenewals == 1 ? "internal renewal" : "internal renewals"
            } pending.`}
            link="/renewals/company-internals"
            linkTitle="View"
            priority="high"
          />
        )} */}

      {renewedDriverCount != 0 &&
        (
          <AlertCard
            icon={<UserRoundCog size={25} className="" />}
            content={`You have ${
              renewedDriverCount > 5 ? "5+" : renewedDriverCount
            } ${
              renewedDriverCount == 1 ? "driver" : "drivers"
            } whose renewal dates are approaching soon.`}
            link="/drivers"
            linkTitle="View"
            priority="high"
          />
        )}

      {renewedVehicleCount != 0 &&
        (
          <AlertCard
            icon={<UserRoundCog size={25} className="" />}
            content={`You have ${
              renewedVehicleCount > 5 ? "5+" : renewedVehicleCount
            } ${
              renewedVehicleCount == 1 ? "vehicle" : "vehicles"
            } whose renewal dates are approaching soon.`}
            link="/cars"
            linkTitle="View"
            priority="high"
          />
        )}

      {otherCount != 0 &&
        (
          <AlertCard
            icon={<UserRoundCog size={25} className="" />}
            content={`You have ${otherCount > 5 ? "5+" : otherCount} ${
              otherCount == 1 ? "internal renewal" : "internal renewals"
            } whose dates are approaching soon.`}
            link="/cars"
            linkTitle="View"
            priority="high"
          />
        )}

      {licenseCount != 0 &&
        (
          <AlertCard
            icon={<UserRoundCog size={25} className="" />}
            content={`You have ${licenseCount > 5 ? "5+" : licenseCount} ${
              licenseCount == 1 ? "driver" : "drivers"
            } whose license will expire soon.`}
            link="/drivers"
            linkTitle="View"
          />
        )}

      {visaCount != 0 &&
        (
          <AlertCard
            icon={<CreditCard size={25} className="" />}
            content={`You have ${visaCount > 5 ? "5+" : visaCount} ${
              visaCount == 1 ? "driver" : "drivers"
            } whose visa will expire soon.`}
            link="/drivers"
            linkTitle="View"
          />
        )}

      {passportCount != 0 &&
        (
          <AlertCard
            icon={<Book size={25} className="" />}
            content={`You have ${passportCount > 5 ? "5+" : passportCount} ${
              passportCount == 1 ? "driver" : "drivers"
            } whose passport will expire soon.`}
            link="/drivers"
            linkTitle="View"
          />
        )}

      {rtaCount != 0 &&
        (
          <AlertCard
            icon={<CreditCard size={25} className="" />}
            content={`You have ${rtaCount > 5 ? "5+" : rtaCount} ${
              rtaCount == 1 ? "driver" : "drivers"
            } whose RTA card will expire soon.`}
            link="/drivers"
            linkTitle="View"
          />
        )}

      {leaveCount != 0 &&
        (
          <AlertCard
            icon={<UserRoundCog size={25} className="" />}
            content={`You have ${leaveCount > 5 ? "5+" : leaveCount} ${
              leaveCount == 1 ? "driver" : "drivers"
            } who will soon be leaving.`}
            link="/drivers"
            linkTitle="View"
          />
        )}

      {eidCount != 0 &&
        (
          <AlertCard
            icon={<CreditCard size={25} className="" />}
            content={`You have ${eidCount > 5 ? "5+" : eidCount} ${
              eidCount == 1 ? "driver" : "drivers"
            } whose EID will expire soon.`}
            link="/drivers"
            linkTitle="View"
          />
        )}

      {vehicleCount != 0 &&
        (
          <AlertCard
            icon={<CarFront size={25} className="" />}
            content={`You have ${vehicleCount > 5 ? "5+" : vehicleCount} ${
              vehicleCount == 1 ? "vehicle" : "vehicles"
            } whose registration will expire soon.`}
            link="/cars"
            linkTitle="View"
          />
        )}
    </div>
  );
};

export default Alert;
