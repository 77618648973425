import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";
import moment from "moment";
import * as XLSX from "xlsx";
import * as Yup from "yup";

// Icons
import {
  AlertTriangle,
  CheckCircle,
  Download,
  Edit,
  Eye,
  Loader,
  MoreHorizontal,
  Printer,
  Search,
  Trash,
} from "lucide-react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { Dropdown } from "Common/Components/Dropdown";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import Modal from "Common/Components/Modal";
import PhotosUploader from "components/Forms/ImageUploader";
import {
  hasDatePassed,
  isDateWithinNext20Days,
  toReadableDate,
  formatDateToInput,
} from "helpers/utils";

const Drivers = () => {
  const [driverList, setDriverList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [creatingDriver, setCreatingDriver] = useState<boolean>(false);
  const [user, setUser] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showView, setShowView] = useState<boolean>(false);
  const [editDriverId, setEditDriverId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAll = async () => {
      await handleGetAllRenewals().then((res) => {
        handleFetchDrivers(res);
      });
      handleFetchUsers();
    };

    fetchAll();
  }, []);

  useEffect(() => {
    setUser(driverList);
  }, [driverList]);

  // fetch data
  const handleFetchUsers = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/user/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setUserList(res.data.users);
      setUserList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            createdAt: moment(item.createdAt).format("DD MMMM, YYYY"),
          };
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchDrivers = async (renewals?: any) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/driver/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setDriverList(res.data);
      if (driverList) {
        setDriverList((prev: any) => {
          return prev.map((item: any) => {
            const renewal = renewals?.find((renewal: any) =>
              renewal.referenceId === item._id
            );
            return {
              ...item,
              createdAt: moment(item.createdAt).format("DD MMMM, YYYY"),
              username: item.user.name,
              renewalStatus: renewal ? renewal.status : "N/A",
            };
          });
        });
        setDriverList((prev: any) => {
          return prev.map((item: any) => {
            return {
              ...item,
              licenseExpiryDate: moment(item.licenseDetails.expiryDate).format(
                "DD MMMM, YYYY",
              ),
              rtaCardExpiryDate: moment(item.rtaCardExpiryDate).format(
                "DD MMMM, YYYY",
              ),
              passportExpiryDate: moment(item.passportExpiryDate).format(
                "DD MMMM, YYYY",
              ),
              eidExpiryDate: moment(item.eidExpiryDate).format(
                "DD MMMM, YYYY",
              ),
              visaExpiryDate: moment(item.visaExpiryDate).format(
                "DD MMMM, YYYY",
              ),
              email: item.user.email,
              licenseNumber: item.licenseDetails.number,
            };
          });
        });
      }
      console.log(res.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllRenewals = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/renewal/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      // setRenewals(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (images.length > 0) {
      validation.setFieldValue("picture", images[0]);
    } else {
      validation.setFieldValue("picture", "");
    }
  }, [images]);

  const deleteDriver = async (id: string) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BASE_URI}/crm/driver/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (res.status === 200) {
        toast.success("Driver deleted successfully!");
        handleFetchUsers();
        handleFetchDrivers();
      }
    } catch (error) {
      toast.error("Failed to delete driver!");
      console.log(error);
    }
  };

  // Search Data
  // const filterSearchData = (e: any) => {
  //   const search = e.target.value;
  //   const keysToSearch = ["user.name", "user.email"];
  //   filterDataBySearch(driverList, search, keysToSearch, setUser);
  // };

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      phone: "",
      licenseNumber: "",
      licenseExpiry: "",
      name: "",
      email: "",
      password: "",
      eidNumber: "",
      eidExpiryDate: "",
      leaveDate: "",
      rtaCardExpiryDate: "",
      rtaPermitNumber: "",
      visaExpiryDate: "",
      passportExpiryDate: "",
      passportNumber: "",
      fileNumber: "",
      trafficFileNumber: "",
      picture: "",
      dateOfBirth: "",
      joiningDate: "",
      nationality: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      email: Yup.string().required("Please Enter Email"),
      password: Yup.string().required("Please Enter Password"),
      phone: Yup.string().required("Please Enter Phone Number"),
      licenseNumber: Yup.string().required("Please Enter License Number"),
      licenseExpiry: Yup.string().required("Please Enter License Expiry Date"),
      leaveDate: Yup.string().required("Please Enter leave date"),
      joiningDate: Yup.string().optional(),
      eidNumber: Yup.string().required("Please Enter EID Number"),
      eidExpiryDate: Yup.string().required("Please Enter EID Expiry Date"),
      rtaCardExpiryDate: Yup.string().required(
        "Please Enter RTA Card Expiry Date",
      ),
      rtaPermitNumber: Yup.string().required("Please Enter RTA Permit Number"),
      visaExpiryDate: Yup.string().required("Please Enter Visa Expiry Date"),
      passportExpiryDate: Yup.string().required(
        "Please Enter Passport Expiry Date",
      ),
      passportNumber: Yup.string().required("Please Enter Passport Number"),
      fileNumber: Yup.string().required("Please Enter File Number"),
      trafficFileNumber: Yup.string().required(
        "Please Enter Traffic File Number",
      ),
      picture: Yup.string(),
      dateOfBirth: Yup.string().required("Please Enter Date Of Birth"),
      nationality: Yup.string().required("Please Enter Nationality"),
    }),

    onSubmit: async (values) => {
      const newData = {
        ...values,
        joiningDate: new Date(values.joiningDate)
      };
      // render any errors in validation
      setCreatingDriver(true);

      if (editDriverId) {
        try {
          await axios.put(
            `${process.env.REACT_APP_BASE_URI}/crm/driver/${editDriverId}`,
            {
              ...newData,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          );
          handleFetchDrivers();
          toast.success("Driver updated successfully!");
          toggle();
          validation.resetForm();
        } catch (error: any) {
          if (!error.response) {
            return toast.error("Network error. Please try again.");
          }
          if (typeof error.response.data === "string") {
            return toast.error(error.response.data);
          }
        } finally {
          setCreatingDriver(false);
          setShowEdit(false);
          setEditDriverId(null);
          setShow(false);
        }
        return;
      }

      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URI}/crm/driver`,
          {
            ...newData,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
        );
        handleFetchDrivers();
        toast.success("Driver made successfully!");
        toggle();
        validation.resetForm();
      } catch (error: any) {
        if (!error.response) {
          return toast.error("Network error. Please try again.");
        }
        if (typeof error.response.data === "string") {
          return toast.error(error.response.data);
        }
      } finally {
        setCreatingDriver(false);
      }
    },
  });

  const toggleEditModal = useCallback((data: any | null) => {
    console.log("edit data", data.user)
    if (showEdit) {
      setShowEdit(false);
      setEditDriverId(null);
    } else {
      setShowEdit(true);
      validation.setFieldValue("phone", data.phone);
      validation.setFieldValue("licenseNumber", data.licenseDetails.number);
      validation.setFieldValue(
        "licenseExpiry",
        data.licenseDetails.expiryDate,
      );
      validation.setFieldValue("name", data.user.name);
      validation.setFieldValue("email", data.user.email);
      validation.setFieldValue("password", data.user.password);
      validation.setFieldValue("eidNumber", data.eidNumber);
      validation.setFieldValue("eidExpiryDate", data.eidExpiryDate);
      validation.setFieldValue("leaveDate", data.leaveDate);
      validation.setFieldValue("rtaCardExpiryDate", data.rtaCardExpiryDate);
      validation.setFieldValue("rtaPermitNumber", data.rtaPermitNumber);
      validation.setFieldValue("dateOfBirth", new Date(data.dateOfBirth));
      validation.setFieldValue("visaExpiryDate", data.visaExpiryDate);
      validation.setFieldValue("passportExpiryDate", data.passportExpiryDate);
      validation.setFieldValue("passportNumber", data.passportNumber);
      validation.setFieldValue("fileNumber", data.fileNumber);
      validation.setFieldValue("trafficFileNumber", data.trafficFileNumber);
      validation.setFieldValue("picture", data.picture);
      validation.setFieldValue("dateOfBirth", data.dateOfBirth);
      validation.setFieldValue("nationality", data.nationality);
      validation.setFieldValue("joiningDate", data.joiningDate);
      setImages([data.picture]);
      setEditDriverId(data._id);
    }
  }, [showEdit, validation]);

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
      validation.resetForm();
    }
  }, [show, validation]);

  // columns
  const Status = ({ item }: any) => {
    switch (item) {
      case true:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
            <CheckCircle className="size-3 mr-1.5" />
            Verified
          </span>
        );
      case false:
        return (
          <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-orange-100 border-transparent text-orange-500 dark:bg-orange-500/20 dark:text-orange-200 dark:border-transparent status">
            <Loader className="size-3 mr-1.5" />
            Pending
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
            <CheckCircle className="size-3 mr-1.5" />
            Unknown
          </span>
        );
    }
  };

  const LicenseNumber = ({ item }: any) => {
    switch (item) {
      case "Approved":
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">
            {item}
          </span>
        );
      case "Pending":
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-yellow-100 border-yellow-200 text-yellow-500 dark:bg-yellow-500/20 dark:border-yellow-500/20">
            {item}
          </span>
        );
      case "Rejected":
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-red-100 border-red-200 text-red-500 dark:bg-red-500/20 dark:border-red-500/20">
            {item}
          </span>
        );
      default:
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">
            {item}
          </span>
        );
    }
  };

  const columns = useMemo(
    () => [
      {
        // header: (
        //   <div className="flex items-center h-full">
        //     <input
        //       id="CheckboxAll"
        //       className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
        //       type="checkbox"
        //     />
        //   </div>
        // ),
        // enableSorting: false,
        id: "checkAll",
        // cell: (cell: any) => {
        //   return (
        //     <div className="flex items-center h-full">
        //       <input
        //         id="Checkbox1"
        //         className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
        //         type="checkbox"
        //       />
        //     </div>
        //   );
        // },
      },
      {
        header: "Name",
        accessorKey: "user.name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.picture
                ? (
                  <img
                    src={cell.row.original.picture}
                    alt=""
                    className="h-10 rounded-full"
                  />
                )
                : (
                  cell
                    .getValue()
                    .split(" ")
                    .map((word: any) => word.charAt(0))
                    .join("")
                )}
            </div>
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Email",
        accessorKey: "user.email",
        enableColumnFilter: false,
      },
      {
        header: "Nationality",
        accessorKey: "nationality",
        enableColumnFilter: false,
      },
      {
        header: "Phone Number",
        accessorKey: "phone",
        enableColumnFilter: false,
      },
      {
        header: "License Number",
        accessorKey: "licenseDetails.number",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <LicenseNumber item={cell.getValue()} />,
      },
      {
        header: "Renewal Status",
        accessorKey: "renewalStatus",
        enableColumnFilter: false,
      },
      {
        header: "Traffic File Number",
        accessorKey: "trafficFileNumber",
        enableColumnFilter: false,
      },
      {
        header: "File Number",
        accessorKey: "fileNumber",
        enableColumnFilter: false,
      },
      {
        header: "License Expiry Date",
        accessorKey: "licenseExpiryDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <p>
              {hasDatePassed(cell.row.original.licenseExpiryDate) ||
                isDateWithinNext20Days(cell.row.original.licenseExpiryDate)
                ? (
                  <p
                    title="License expiring soon!"
                    className="flex flex-row justify-around text-red-500 px-2 items-center bg-red-50 rounded-md p-1"
                  >
                    <AlertTriangle className="text-red-500" size={15} />
                    {cell.row.original.licenseExpiryDate}
                  </p>
                )
                : (
                  <p className="">
                    {cell.row.original.licenseExpiryDate}
                  </p>
                )}
            </p>
          </div>
        ),
      },
      {
        header: "RTA Card Expiry Date",
        accessorKey: "rtaCardExpiryDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <p>
              {hasDatePassed(cell.row.original.rtaCardExpiryDate) ||
                isDateWithinNext20Days(cell.row.original.rtaCardExpiryDate)
                ? (
                  <p
                    title="RTA card expiring soon!"
                    className="flex flex-row justify-around text-red-500 px-2 items-center bg-red-50 rounded-md p-1"
                  >
                    <AlertTriangle className="text-red-500" size={15} />
                    {cell.row.original.rtaCardExpiryDate}
                  </p>
                )
                : (
                  <p className="">
                    {cell.row.original.rtaCardExpiryDate}
                  </p>
                )}
            </p>
          </div>
        ),
      },
      {
        header: "Leave Date",
        accessorKey: "leaveDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <p>
              {cell.row.original.leaveDate != null
                ? hasDatePassed(cell.row.original.leaveDate) ||
                  isDateWithinNext20Days(cell.row.original.leaveDate)
                  ? (
                    <p
                      title="Retirement coming soon!"
                      className="flex flex-row justify-around gap-2 text-red-500 px-2 items-center bg-red-50 rounded-md p-1"
                    >
                      <AlertTriangle className="text-red-500" size={15} />
                      {moment(cell.row.original.leaveDate).format(
                        "DD MMMM, YYYY",
                      )}
                    </p>
                  )
                  : (
                    <p className="">
                      {moment(cell.row.original.leaveDate).format(
                        "DD MMMM, YYYY",
                      )}
                    </p>
                  )
                : <p>N/A</p>}
            </p>
          </div>
        ),
      },
      {
        header: "EID Expiry Date",
        accessorKey: "eidExpiryDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <p>
              {hasDatePassed(cell.row.original.eidExpiryDate) ||
                isDateWithinNext20Days(cell.row.original.eidExpiryDate)
                ? (
                  <p
                    title="EID expiring soon!"
                    className="flex flex-row justify-around gap-2 text-red-500 px-2 items-center bg-red-50 rounded-md p-1"
                  >
                    <AlertTriangle className="text-red-500" size={15} />
                    {cell.row.original.eidExpiryDate}
                  </p>
                )
                : (
                  <p className="">
                    {cell.row.original.eidExpiryDate}
                  </p>
                )}
            </p>
          </div>
        ),
      },
      {
        header: "Visa Expiry Date",
        accessorKey: "visaExpiryDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <p>
              {hasDatePassed(cell.row.original.visaExpiryDate) ||
                isDateWithinNext20Days(cell.row.original.visaExpiryDate)
                ? (
                  <p
                    title="Visa expiring soon!"
                    className="flex flex-row justify-around gap-2 text-red-500 px-2 items-center bg-red-50 rounded-md p-1"
                  >
                    <AlertTriangle className="text-red-500" size={15} />
                    {cell.row.original.visaExpiryDate}
                  </p>
                )
                : (
                  <p className="">
                    {cell.row.original.visaExpiryDate}
                  </p>
                )}
            </p>
          </div>
        ),
      },
      {
        header: "Passport Expiry Date",
        accessorKey: "passportExpiryDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <p>
              {hasDatePassed(cell.row.original.passportExpiryDate) ||
                isDateWithinNext20Days(cell.row.original.passportExpiryDate)
                ? (
                  <p
                    title="Passport expiring soon!"
                    className="flex flex-row justify-around gap-2 text-red-500 px-2 items-center bg-red-50 rounded-md p-1"
                  >
                    <AlertTriangle className="text-red-500" size={15} />
                    {cell.row.original.passportExpiryDate}
                  </p>
                )
                : (
                  <p className="">
                    {cell.row.original.passportExpiryDate}
                  </p>
                )}
            </p>
          </div>
        ),
      },
      {
        header: "Joining Date",
        accessorKey: "createdAt",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              id="orderAction1"
              data-bs-toggle="dropdown"
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement={cell.row.index ? "top-end" : "right-end"}
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="orderAction1"
            >
              <li
                onClick={() => toggleEditModal(cell.row.original)}
              >
                <div className="block px-4 py-1.5 text-base transition-all cursor-pointer duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  <Edit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                  {" "}
                  <span className="align-middle">Edit</span>
                </div>
              </li>{" "}
              <li>
                <Link
                  to={`/drivers/${cell.row.original._id}`}
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                >
                  <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">View</span>
                </Link>
              </li>
              <li
                onClick={() => deleteDriver(cell.row.original._id)}
              >
                <p className="block cursor-pointer px-4 py-1.5 text-base transition-all duration-200 ease-linear text-red-500 hover:bg-red-100 hover:text-red-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  <Trash className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                  {" "}
                  <span className="align-middle">Delete</span>
                </p>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    [],
  );

  function handlePrint() {
    window.print();
  }

  const dowloadDriversInExcel = async () => {
    const data = user.map((item: any) => {
      return {
        "Driver ID": item._id,
        "User ID": item.user._id,
        Name: item.user.name,
        Email: item.user.email,
        "License Number": item.licenseDetails.number,
        "License Expiry Date": item.licenseDetails.expiryDate,
        "Traffic File Number": item.trafficFileNumber,
        "File Number": item.fileNumber,
        "RTA Permit Number": item.rtaPermitNumber,
        "RTA Card Expiry Date": item.rtaCardExpiryDate,
        "EID Number": item.eidNumber,
        "EID Expiry Date": item.eidExpiryDate,
        "Passport Number": item.passportNumber,
        "Passport Expiry Date": item.passportExpiryDate,
        "Visa Expiry Date": item.visaExpiryDate,
        "Phone Number": item.phone,
        "Nationality": item.nationality,
        "Renewal Status": item.renewalStatus,
        "Joining Date": item.createdAt,
      };
    });

    const fileName = "Drivers";
    const exportType = "xls";
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.${exportType}`);
  };

  const filterSearchData = (e: any) => {
    const search = e.target.value;
    console.log(driverList);
    const keysToSearch = [
      "_id",
      "licenseNumber",
      "createdAt",
      "username",
      "email",
      "phone",
    ];
    const searchResult = driverList.filter((item: any) => {
      return keysToSearch.some((key) => {
        return (
          item[key] &&
          item[key].toString().toLowerCase().includes(search.toLowerCase())
        );
      });
    });
    setUser(searchResult);
  };

  return (
    <React.Fragment>
      <BreadCrumb title="Drivers" pageTitle="Drivers" />
      <ToastContainer closeButton={false} limit={1} />
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="Drivers">
            <div className="card-body">
              <div className="flex gap-2">
                <div className="ml-auto flex flex-row gap-2">
                  <button
                    onClick={handlePrint}
                    type="button"
                    className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                  >
                    <Printer className="inline-block size-4" />{" "}
                    <span className="align-middle">Print page</span>
                  </button>
                  <button
                    onClick={dowloadDriversInExcel}
                    type="button"
                    className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                  >
                    <Download className="inline-block size-4" />{" "}
                    <span className="align-middle">Export</span>
                  </button>
                </div>
              </div>
              <div className="flex items-center">
                <h6 className="text-15 grow">Drivers List</h6>
              </div>
              <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                <form action="#!">
                  <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                    <div className="relative xl:col-span-2">
                      <input
                        type="text"
                        className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="Search for name, email, phone number etc..."
                        autoComplete="off"
                        onChange={(e) => filterSearchData(e)}
                      />
                      <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                    </div>

                    <div className="xl:col-span-3 xl:col-start-10">
                      <div className="flex gap-2 xl:justify-end">
                        <div>
                          <button
                            type="button"
                            className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                            onClick={toggle}
                          >
                            <span className="align-middle">Add driver</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-body">
              {user && user.length > 0
                ? (
                  <TableContainer
                    isPagination={true}
                    columns={columns || []}
                    data={user || []}
                    customPageSize={25}
                    divclassName="-mx-5 -mb-5 overflow-x-auto"
                    tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                    theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                    thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                    tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                    PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                  />
                )
                : (
                  <div className="noresult">
                    <div className="py-6 text-center">
                      <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                      <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                      <p className="mb-0 text-slate-500 dark:text-zink-200">
                        We've searched more than 199+ users We did not find any
                        users for you search.
                      </p>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>

      {/* User Modal  */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {"Create Driver"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            {
              /* <div className="mb-3">
              <label
                htmlFor="user"
                className="inline-block mb-2 text-base font-medium"
              >
                User
              </label>
              <select
                className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                id="user"
                name="user"
                onChange={validation.handleChange}
                value={validation.values.user || ""}
              >
                <option value={""} disabled selected>Select a driver</option>
                {userList.map((item: any) => {
                  return (
                    <option key={item._id} value={item._id}>{item.name}</option>
                  );
                })}
              </select>
            </div> */
            }

            <div className="mb-3">
              <label
                htmlFor="name"
                className="inline-block mb-2 text-base font-medium"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=""
                name="name"
                onChange={validation.handleChange}
                value={validation.values.name || ""}
              />
              {validation.touched.name &&
                validation.errors.name
                ? (
                  <p className="text-red-400">
                    {validation.errors.name}
                  </p>
                )
                : null}
            </div>

            <div className="mb-3">
              <label
                htmlFor="email"
                className="inline-block mb-2 text-base font-medium"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=""
                name="email"
                onChange={validation.handleChange}
                value={validation.values.email || ""}
              />
              {validation.touched.email &&
                validation.errors.email
                ? (
                  <p className="text-red-400">
                    {validation.errors.email}
                  </p>
                )
                : null}
            </div>

            <div className="mb-3">
              <label
                htmlFor="email"
                className="inline-block mb-2 text-base font-medium"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=""
                name="password"
                onChange={validation.handleChange}
                value={validation.values.password || ""}
              />
              {validation.touched.password &&
                validation.errors.password
                ? (
                  <p className="text-red-400">
                    {validation.errors.password}
                  </p>
                )
                : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="joiningDate"
                className="inline-block mb-2 text-base font-medium"
              >
                Joining Date
              </label>
              <input
                type="date"
                id="joiningDate"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=""
                name="joiningDate"
                onChange={validation.handleChange}
                value={formatDateToInput(validation.values.joiningDate) || ""}
              />
              {validation.touched.joiningDate &&
                validation.errors.joiningDate
                ? (
                  <p className="text-red-400">
                    {validation.errors.joiningDate}
                  </p>
                )
                : null}
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div className="mb-3">
                <label
                  htmlFor="licenseNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Driving License Number
                </label>
                <input
                  type="text"
                  id="licenseNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="licenseNumber"
                  onChange={validation.handleChange}
                  value={validation.values.licenseNumber || ""}
                />
                {validation.touched.licenseNumber &&
                  validation.errors.licenseNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.licenseNumber}
                    </p>
                  )
                  : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="expenseDate"
                  className="inline-block mb-2 text-base font-medium"
                >
                  License Expiry Date
                </label>
                <input
                  type="date"
                  id="licenseExpiry"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="licenseExpiry"
                  onChange={validation.handleChange}
                  value={validation.values.licenseExpiry || ""}
                />
                {validation.touched.licenseExpiry &&
                  validation.errors.licenseExpiry
                  ? (
                    <p className="text-red-400">
                      {validation.errors.licenseExpiry}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="mb-3">
                <label
                  htmlFor="rtaPermitNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  RTA Permit Number
                </label>
                <input
                  type="text"
                  id="rtaPermitNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="rtaPermitNumber"
                  onChange={validation.handleChange}
                  value={validation.values.rtaPermitNumber || ""}
                />
                {validation.touched.rtaPermitNumber &&
                  validation.errors.rtaPermitNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.rtaPermitNumber}
                    </p>
                  )
                  : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="rtaCartExpiryDate"
                  className="inline-block mb-2 text-base font-medium"
                >
                  RTA Card Expiry
                </label>
                <input
                  type="date"
                  id="rtaCardExpiryDate"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="rtaCardExpiryDate"
                  onChange={validation.handleChange}
                  value={validation.values.rtaCardExpiryDate || ""}
                />
                {validation.touched.rtaCardExpiryDate &&
                  validation.errors.rtaCardExpiryDate
                  ? (
                    <p className="text-red-400">
                      {validation.errors.rtaCardExpiryDate}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="mb-3">
                <label
                  htmlFor="passportNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Passport Number
                </label>
                <input
                  type="text"
                  id="passportNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="passportNumber"
                  onChange={validation.handleChange}
                  value={validation.values.passportNumber || ""}
                />
                {validation.touched.passportNumber &&
                  validation.errors.passportNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.passportNumber}
                    </p>
                  )
                  : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="passportExpiryDate"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Passport Expiry
                </label>
                <input
                  type="date"
                  id="passportExpiryDate"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="passportExpiryDate"
                  onChange={validation.handleChange}
                  value={validation.values.passportExpiryDate || ""}
                />
                {validation.touched.passportExpiryDate &&
                  validation.errors.passportExpiryDate
                  ? (
                    <p className="text-red-400">
                      {validation.errors.passportExpiryDate}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="mb-3">
                <label
                  htmlFor="eidNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  EID Number
                </label>
                <input
                  type="text"
                  id="eidNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="eidNumber"
                  onChange={validation.handleChange}
                  value={validation.values.eidNumber || ""}
                />
                {validation.touched.eidNumber &&
                  validation.errors.eidNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.eidNumber}
                    </p>
                  )
                  : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="eidExpiryDate"
                  className="inline-block mb-2 text-base font-medium"
                >
                  EID Expiry
                </label>
                <input
                  type="date"
                  id="eidExpiryDate"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="eidExpiryDate"
                  onChange={validation.handleChange}
                  value={validation.values.eidExpiryDate || ""}
                />
                {validation.touched.eidExpiryDate &&
                  validation.errors.eidExpiryDate
                  ? (
                    <p className="text-red-400">
                      {validation.errors.eidExpiryDate}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="mb-3">
                <label
                  htmlFor="fileNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  File Number
                </label>
                <input
                  type="text"
                  id="fileNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="fileNumber"
                  onChange={validation.handleChange}
                  value={validation.values.fileNumber || ""}
                />
                {validation.touched.fileNumber &&
                  validation.errors.fileNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.fileNumber}
                    </p>
                  )
                  : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="trafficFileNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Traffic File Number
                </label>
                <input
                  type="text"
                  id="trafficFileNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="trafficFileNumber"
                  onChange={validation.handleChange}
                  value={validation.values.trafficFileNumber || ""}
                />
                {validation.touched.trafficFileNumber &&
                  validation.errors.trafficFileNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.trafficFileNumber}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="mb-3">
                <label
                  htmlFor="dateOfBirth"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="dateOfBirth"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="dateOfBirth"
                  onChange={validation.handleChange}
                  value={validation.values.dateOfBirth || ""}
                />
                {validation.touched.dateOfBirth &&
                  validation.errors.dateOfBirth
                  ? (
                    <p className="text-red-400">
                      {validation.errors.dateOfBirth}
                    </p>
                  )
                  : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="visaExpiryDate"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Visa Expiry
                </label>
                <input
                  type="date"
                  id="visaExpiryDate"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="visaExpiryDate"
                  onChange={validation.handleChange}
                  value={validation.values.visaExpiryDate || ""}
                />
                {validation.touched.visaExpiryDate &&
                  validation.errors.visaExpiryDate
                  ? (
                    <p className="text-red-400">
                      {validation.errors.visaExpiryDate}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="leaveDate"
                className="inline-block mb-2 text-base font-medium"
              >
                Leave Date
              </label>
              <input
                type="date"
                id="leaveDate"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=""
                name="leaveDate"
                onChange={validation.handleChange}
                value={validation.values.leaveDate || ""}
              />
              {validation.touched.leaveDate &&
                validation.errors.leaveDate
                ? (
                  <p className="text-red-400">
                    {validation.errors.leaveDate}
                  </p>
                )
                : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="phone"
                className="inline-block mb-2 text-base font-medium"
              >
                Phone Number
              </label>
              <input
                type="text"
                id="phone"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="+1 234 12344323"
                name="phone"
                onChange={validation.handleChange}
                value={validation.values.phone || ""}
              />
              {validation.touched.phone &&
                validation.errors.phone
                ? <p className="text-red-400">{validation.errors.phone}</p>
                : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="nationality"
                className="inline-block mb-2 text-base font-medium"
              >
                Nationality
              </label>
              <input
                type="text"
                id="nationality"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Arabian"
                name="nationality"
                onChange={validation.handleChange}
                value={validation.values.nationality || ""}
              />
              {validation.touched.nationality &&
                validation.errors.nationality
                ? (
                  <p className="text-red-400">
                    {validation.errors.nationality}
                  </p>
                )
                : null}
            </div>
            <div className="xl:col-span-12">
              <label
                htmlFor="fileUpload"
                className="inline-block mb-2 text-base font-medium"
              >
                Upload Picture
              </label>
              <PhotosUploader
                maxPhotos={1}
                addedPhotos={images}
                onChange={(photos: any) => {
                  setImages(photos);
                }}
              />
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {"Add Driver"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Edit Modal */}
      <Modal
        show={showEdit}
        onHide={toggleEditModal}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {"Update Driver"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              console.log("form submitted")
              console.log(validation.values)
              validation.handleSubmit();
              console.log("validation error")
              return false;
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="name"
                className="inline-block mb-2 text-base font-medium"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=""
                name="name"
                onChange={validation.handleChange}
                value={validation.values.name || ""}
              />
              {validation.touched.name &&
                validation.errors.name
                ? (
                  <p className="text-red-400">
                    {validation.errors.name}
                  </p>
                )
                : null}
            </div>

            <div className="mb-3">
              <label
                htmlFor="email"
                className="inline-block mb-2 text-base font-medium"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=""
                name="email"
                onChange={validation.handleChange}
                value={validation.values.email || ""}
              />
              {validation.touched.email &&
                validation.errors.email
                ? (
                  <p className="text-red-400">
                    {validation.errors.email}
                  </p>
                )
                : null}
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div className="mb-3">
                <label
                  htmlFor="licenseNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Driving License Number
                </label>
                <input
                  type="text"
                  id="licenseNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="licenseNumber"
                  onChange={validation.handleChange}
                  value={validation.values.licenseNumber || ""}
                />
                {validation.touched.licenseNumber &&
                  validation.errors.licenseNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.licenseNumber}
                    </p>
                  )
                  : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="expenseDate"
                  className="inline-block mb-2 text-base font-medium"
                >
                  License Expiry Date
                </label>
                <input
                  type="date"
                  id="licenseExpiry"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="licenseExpiry"
                  onChange={validation.handleChange}
                  value={formatDateToInput(validation.values.licenseExpiry) || ""}
                />
                {validation.touched.licenseExpiry &&
                  validation.errors.licenseExpiry
                  ? (
                    <p className="text-red-400">
                      {validation.errors.licenseExpiry}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="mb-3">
                <label
                  htmlFor="rtaPermitNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  RTA Permit Number
                </label>
                <input
                  type="text"
                  id="rtaPermitNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="rtaPermitNumber"
                  onChange={validation.handleChange}
                  value={validation.values.rtaPermitNumber || ""}
                />
                {validation.touched.rtaPermitNumber &&
                  validation.errors.rtaPermitNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.rtaPermitNumber}
                    </p>
                  )
                  : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="rtaCartExpiryDate"
                  className="inline-block mb-2 text-base font-medium"
                >
                  RTA Card Expiry
                </label>
                <input
                  type="date"
                  id="rtaCardExpiryDate"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="rtaCardExpiryDate"
                  onChange={validation.handleChange}
                  value={formatDateToInput(validation.values.rtaCardExpiryDate) || ""}
                />
                {validation.touched.rtaCardExpiryDate &&
                  validation.errors.rtaCardExpiryDate
                  ? (
                    <p className="text-red-400">
                      {validation.errors.rtaCardExpiryDate}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="mb-3">
                <label
                  htmlFor="passportNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Passport Number
                </label>
                <input
                  type="text"
                  id="passportNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="passportNumber"
                  onChange={validation.handleChange}
                  value={validation.values.passportNumber || ""}
                />
                {validation.touched.passportNumber &&
                  validation.errors.passportNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.passportNumber}
                    </p>
                  )
                  : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="passportExpiryDate"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Passport Expiry
                </label>
                <input
                  type="date"
                  id="passportExpiryDate"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="passportExpiryDate"
                  onChange={validation.handleChange}
                  value={formatDateToInput(validation.values.passportExpiryDate) || ""}
                />
                {validation.touched.passportExpiryDate &&
                  validation.errors.passportExpiryDate
                  ? (
                    <p className="text-red-400">
                      {validation.errors.passportExpiryDate}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="mb-3">
                <label
                  htmlFor="eidNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  EID Number
                </label>
                <input
                  type="text"
                  id="eidNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="eidNumber"
                  onChange={validation.handleChange}
                  value={validation.values.eidNumber || ""}
                />
                {validation.touched.eidNumber &&
                  validation.errors.eidNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.eidNumber}
                    </p>
                  )
                  : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="eidExpiryDate"
                  className="inline-block mb-2 text-base font-medium"
                >
                  EID Expiry
                </label>
                <input
                  type="date"
                  id="eidExpiryDate"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="eidExpiryDate"
                  onChange={validation.handleChange}
                  value={formatDateToInput(validation.values.eidExpiryDate) || ""}
                />
                {validation.touched.eidExpiryDate &&
                  validation.errors.eidExpiryDate
                  ? (
                    <p className="text-red-400">
                      {validation.errors.eidExpiryDate}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="mb-3">
                <label
                  htmlFor="fileNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  File Number
                </label>
                <input
                  type="text"
                  id="fileNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="fileNumber"
                  onChange={validation.handleChange}
                  value={validation.values.fileNumber || ""}
                />
                {validation.touched.fileNumber &&
                  validation.errors.fileNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.fileNumber}
                    </p>
                  )
                  : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="trafficFileNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Traffic File Number
                </label>
                <input
                  type="text"
                  id="trafficFileNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="trafficFileNumber"
                  onChange={validation.handleChange}
                  value={validation.values.trafficFileNumber || ""}
                />
                {validation.touched.trafficFileNumber &&
                  validation.errors.trafficFileNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.trafficFileNumber}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="mb-3">
                <label
                  htmlFor="dateOfBirth"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="dateOfBirth"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="dateOfBirth"
                  onChange={validation.handleChange}
                  value={formatDateToInput(validation.values.dateOfBirth) || ""}
                />
                {validation.touched.dateOfBirth &&
                  validation.errors.dateOfBirth
                  ? (
                    <p className="text-red-400">
                      {validation.errors.dateOfBirth}
                    </p>
                  )
                  : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="visaExpiryDate"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Visa Expiry
                </label>
                <input
                  type="date"
                  id="visaExpiryDate"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=""
                  name="visaExpiryDate"
                  onChange={validation.handleChange}
                  value={formatDateToInput(validation.values.visaExpiryDate) || ""}
                />
                {validation.touched.visaExpiryDate &&
                  validation.errors.visaExpiryDate
                  ? (
                    <p className="text-red-400">
                      {validation.errors.visaExpiryDate}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="leaveDate"
                className="inline-block mb-2 text-base font-medium"
              >
                Leave Date
              </label>
              <input
                type="date"
                id="leaveDate"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=""
                name="leaveDate"
                onChange={validation.handleChange}
                value={formatDateToInput(validation.values.leaveDate) || ""}
              />
              {validation.touched.leaveDate &&
                validation.errors.leaveDate
                ? (
                  <p className="text-red-400">
                    {validation.errors.leaveDate}
                  </p>
                )
                : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="phone"
                className="inline-block mb-2 text-base font-medium"
              >
                Phone Number
              </label>
              <input
                type="text"
                id="phone"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="+1 234 12344323"
                name="phone"
                onChange={validation.handleChange}
                value={validation.values.phone || ""}
              />
              {validation.touched.phone &&
                validation.errors.phone
                ? <p className="text-red-400">{validation.errors.phone}</p>
                : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="nationality"
                className="inline-block mb-2 text-base font-medium"
              >
                Nationality
              </label>
              <input
                type="text"
                id="nationality"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Arabian"
                name="nationality"
                onChange={validation.handleChange}
                value={validation.values.nationality || ""}
              />
              {validation.touched.nationality &&
                validation.errors.nationality
                ? (
                  <p className="text-red-400">
                    {validation.errors.nationality}
                  </p>
                )
                : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="joiningDate"
                className="inline-block mb-2 text-base font-medium"
              >
                Joining Date
              </label>
              <input
                type="date"
                id="joiningDate"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=""
                name="joiningDate"
                onChange={validation.handleChange}
                value={formatDateToInput(validation.values.joiningDate) || ""}
              />
              {validation.touched.joiningDate &&
                validation.errors.joiningDate
                ? (
                  <p className="text-red-400">
                    {validation.errors.joiningDate}
                  </p>
                )
                : null}
            </div>

            <div className="xl:col-span-12">
              <label
                htmlFor="fileUpload"
                className="inline-block mb-2 text-base font-medium"
              >
                Upload Picture
              </label>
              <PhotosUploader
                maxPhotos={1}
                addedPhotos={images}
                onChange={(photos: any) => {
                  setImages(photos);
                }}
              />
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {"Add Driver"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Drivers;
