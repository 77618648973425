// @ts-nocheck
import { expenseTypes } from "Common/constants/expense";
import { parseDateToReadableFormat, toReadableDate } from "helpers/utils";
import letterHead from "assets/images/letterHead.jpg";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
  },
  headerRow: {
    flexDirection: "row",
    marginBottom: 5,
  },
  headerLabel: {
    width: "30%",
    fontWeight: "bold",
  },
  headerValue: {
    width: "70%",
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
  },
});

// sum up amount field in all expenses
const sumAmount = (expenses: any) => {
  return expenses?.reduce((acc: any, item: any) => acc + item.amount, 0);
};

const sumSales = (sales: any) => {
  return sales?.reduce((acc: any, item: any) => acc + item.saleAmount, 0);
};

// Create Document Component
const ReportPdf = ({
  report,
  installments,
  dateRange,
}: {
  report: any;
  installments: number;
  dateRange: any;
}) => {
  const renderSaleItem = (service, title) => {
    const filteredSales = report?.sales.filter(
      (item) => item.service === service
    );
    if (filteredSales && filteredSales.length > 0) {
      return (
        <View className="flex flex-col w-full">
          <Text className="font-semibold">{title}</Text>
          {filteredSales.map((sale, index) => (
            <View
              key={index}
              className="flex flex-row justify-between w-full pl-4"
            >
              <Text>{toReadableDate(sale.saleDate)}</Text>
              <Text>{sale.saleAmount.toFixed(2)}</Text>
            </View>
          ))}
          <View className="flex flex-row justify-between w-full border-t border-gray-300 mt-1 mb-2">
            <Text className="font-semibold">Total</Text>
            <Text>
              {filteredSales.reduce(
                (total, item) => total + item.saleAmount,
                0
              ).toFixed(2)}
            </Text>
          </View>
        </View>
      );
    }
    return null;
  };

  const renderNotes = () => {
    const noteSales = report?.sales.filter(
      (item) => item.service === "note" && item.notes !== ""
    );
    if (noteSales && noteSales.length > 0) {
      return (
        <View className="flex flex-row items-center justify-between w-full">
          <Text className="w-1/3 font-semibold">NOTE</Text>
          <View className="w-full">
            {noteSales.map((item, index) => (
              <View key={index} className="flex flex-row justify-between pb-1">
                <Text>{item.notes}</Text>
                <Text>{item.saleAmount}</Text>
              </View>
            ))}
          </View>
        </View>
      );
    }
    return null;
  };

  const renderExpenseItem = (expenseType) => {
    const filteredExpenses = report?.expenses.filter(
      (expense) =>
        expense.expenseType.toLowerCase() === expenseType.toLowerCase() &&
        expenseType.toLowerCase() !== "fine"
    );
    if (filteredExpenses && filteredExpenses.length > 0) {
      const totalAmount = filteredExpenses.reduce(
        (acc, item) => acc + item.amount,
        0
      ).toFixed(2)
      return (
        <View className="flex flex-row justify-between w-full">
          <Text className="w-1/3 font-semibold">
            {expenseType.toUpperCase()}
          </Text>
          <Text>{totalAmount}</Text>
        </View>
      );
    }
    return null;
  };

  const renderExpenseNotes = () => {
    const expensesWithNotes = report?.expenses.filter(
      (item) => item.description !== ""
    );
    if (expensesWithNotes && expensesWithNotes.length > 0) {
      return (
        <View className="flex flex-col items-center mb-4 justify-between w-full">
          {expensesWithNotes.map((item, index) => (
            <View key={index} className="flex flex-row justify-between w-full">
              <Text className="font-semibold">NOTE</Text>
              <Text className="w-full text-end">{item.description}</Text>
            </View>
          ))}
        </View>
      );
    }
    return null;
  };

  const formatDateRange = (range) => {
    if (!range.startDate || !range.endDate) return "";
    const start = toReadableDate(range.startDate);
    const end = toReadableDate(range.endDate);
    return `${start} - ${end}`;
  };

  const renderFines = () => {
    const fines = report?.expenses.filter(
      (item) => item.expenseType === "fine"
    );
    if (fines && fines.length > 0) {
      return (
        <>
          <View className="flex font-semibold flex-row justify-between">
            <Text>Fines Detail</Text>
            <Text>Amount</Text>
          </View>
          {fines.map((item, index) => {
            const { readableDate, readableTime } = parseDateToReadableFormat(
              item.expenseDate
            );
            return (
              <View
                key={index}
                className="flex flex-row justify-between w-full"
              >
                <Text className="w-1/4">{index + 1}</Text>
                <Text className="w-full text-end flex flex-row items-center justify-around">
                  <span>{readableDate}</span>
                  <span>{readableTime}</span>
                </Text>
                <Text className="w-1/4 text-end">{item.amount}</Text>
              </View>
            );
          })}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Document style={{ width: "100%" }} className="w-full">
        <Page
          size="A4"
          style={styles.page}
          className="flex relative flex-col w-full min-h-screen"
        >
          <img src={letterHead} className="w-full relative top-0" />
          <View
            style={styles.section}
            className="flex absolute top-32 lg:text-sm text-xs z-10 flex-col gap-1"
          >
            <View className="flex flex-row md:w-1/2 w-full  gap-4">
              <Text className="font-semibold w-1/3">Employee Name</Text>
              <Text className="w-2/3">{report?.user.name}</Text>
            </View>
            <View className="flex flex-row md:w-1/2 w-full gap-4">
              <Text className="font-semibold w-1/3">Report Period</Text>
              <Text className="w-2/3">{formatDateRange(dateRange)}</Text>
            </View>
            <View className="flex flex-row md:w-1/2 w-full  gap-4">
              <Text className="font-semibold w-1/3">Passport</Text>
              <Text className="w-2/3">{report?.passportNumber}</Text>
            </View>
            <View className="flex flex-row md:w-1/2 w-full  gap-4">
              <Text className="font-semibold w-1/3">EID</Text>
              <Text className="w-2/3">{report?.eidNumber}</Text>
            </View>
            <View className="flex flex-row md:w-1/2 w-full  gap-4">
              <Text className="font-semibold w-1/3">Designation</Text>
              <Text className="w-2/3">Driver</Text>
            </View>
            <View className="flex flex-row md:w-1/2 w-full gap-4">
              <Text className="font-semibold w-1/3">Date of Joining</Text>
              <Text className="w-2/3">
                {report ? toReadableDate(report?.joiningDate) : ""}
              </Text>
            </View>
            <View className="flex flex-row md:w-1/2 w-full  gap-4">
              <Text className="font-semibold w-1/3">Installments</Text>
              <Text className="w-2/3">{installments}</Text>
            </View>
            <View className="flex flex-row md:w-1/2 w-full  gap-4">
              <Text className="font-semibold w-1/3">Vehicle Plate No.</Text>
              <Text className="w-2/3">
                {report?.assignedVehicle.plateNumber}
              </Text>
            </View>
            <View className="flex flex-row md:w-1/2 w-full  gap-4">
              <Text className="font-semibold w-1/3">Assigned Vehicle</Text>
              <Text className="w-2/3">
                {report?.assignedVehicle.make}, {report?.assignedVehicle.model}
              </Text>
            </View>
            <View className="flex flex-col gap-1">
              <Text className="font-semibold">Assigned Vehicle History</Text>
              <Text>
                {report?.assignedVehiclesHistory?.map(
                  (vehicle: any, index: any) => (
                    <View className="flex flex-row justify-between gap-5">
                      <Text>
                        {++index}. {vehicle.vehicle?.make},{" "}
                        {vehicle.vehicle?.model}
                      </Text>
                      <Text className="opacity-80">
                        {toReadableDate(vehicle.startDate)}
                      </Text>
                    </View>
                  )
                )}
              </Text>
            </View>
            <View className="flex mt-5 flex-row w-full text-center items-center justify-center font-semibold lg:text-md text-sm bg-primary-green/30 p-1 rounded text-primary-green">
              <Text className="w-1/2">Income</Text>
              <Text className="w-1/2">Deduction</Text>
            </View>

            <View className="flex mt-2 flex-row gap-5 lg:text-sm text-xs w-full z-10">
              <View className="flex flex-col w-full">
                <View className="flex flex-col w-full gap-1">
                  <View className="flex font-semibold flex-row justify-between">
                    <Text>Particulars</Text>
                    <Text>Amount AED</Text>
                  </View>
                  {renderSaleItem("uber", "UBER")}
                  {renderSaleItem("yango", "YANGO")}
                  {renderSaleItem("careem", "CAREEM")}
                  {renderSaleItem("pos", "POS MACHINE")}
                  {renderSaleItem("tyre-change", "TYRE CHANGE")}
                  {renderSaleItem("vehicle-services", "VEHICLE SERVICES")}
                  {renderSaleItem("passing", "PASSING")}
                  {renderSaleItem("settlement", "SETTLEMENT")}
                  {renderSaleItem("other", "OTHER")}
                  {renderNotes()}
                  <View className="flex mt-2 border-2 border-primary-green/30 rounded p-1 font-semibold items-center flex-row justify-between">
                    <Text className="text-primary-green">Total</Text>
                    <Text className="text-primary-green">
                      {report ? sumSales(report?.sales).toFixed(2) : 0}
                    </Text>
                  </View>
                </View>
              </View>

              <View className="flex flex-col w-full">
                <View className="flex lg:text-sm text-xs flex-col w-full gap-1">
                  <View className="flex font-semibold flex-row justify-between">
                    <Text>Particulars</Text>
                    <Text>Amount AED</Text>
                  </View>
                  {expenseTypes.map((item) => renderExpenseItem(item.value))}
                  {renderExpenseNotes()}
                  {renderFines()}
                  <View className="flex mt-2 border-2 border-primary-green/30 rounded p-1 items-center font-semibold flex-row justify-between">
                    <Text className="text-primary-green">Total</Text>
                    <Text className="text-primary-green">
                      {report ? sumAmount(report?.expenses).toFixed(2) : 0}
                    </Text>
                  </View>
                  <View className="flex mt-2 bg-primary-green/30 rounded p-1 items-center font-semibold flex-row justify-between">
                    <Text className="text-primary-green">Net Salary AED</Text>
                    <Text className="text-primary-green">
                      {report
                        ? Number(
                            (
                              Number(sumSales(report?.sales)) -
                              Number(sumAmount(report?.expenses))
                            ).toFixed(2)
                          )
                        : 0}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <div className="relative gap-16 flex-col lg:text-sm text-xs top-10 mx-auto flex justify-center items-center">
              <div className=" w-full text-center items-center flex justify-center">
                <p className="xl:w-4/5 w-full">
                  I, <b>{report?.user.name}</b> Certify That the Above
                  Information Given Are True and Correct And I Acknowledge That
                  There Is No Pending Dues Such As Salary, Bonus, Or Any Other
                  Benefits with <br /> SZL LUXURY MOTOR VEHICLES SERVICES LLC,
                  DUBAI UAE
                </p>
              </div>
              <div className="xl:w-6/7 w-full lg:text-sm text-xs flex flex-row text-center items-center justify-center">
                <p className="w-1/3">Employee Signature</p>
                <p className="w-1/3">Accountant Signature</p>
                <p className="w-1/3 whitespace-nowrap">
                  Company Seal & Signature
                </p>
              </div>
            </div>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default ReportPdf;
