import {
  BadgeDollarSign,
  Building,
  Car,
  FileText,
  MonitorDot,
  UserRound,
} from "lucide-react";

const menuData: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboard",
    link: "/",
    icon: <MonitorDot />,
  },
  {
    label: "Drivers",
    isTitle: true,
  },
  {
    id: "drivers",
    label: "Manage Drivers",
    link: "/drivers",
    icon: <Car />,
  },
  {
    label: "Cars",
    isTitle: true,
  },
  {
    id: "cars",
    label: "Manage Cars",
    link: "/cars",
    icon: <Car />,
  },
  // {
  //   label: "Financial",
  //   isTitle: true,
  // },
  // {
  //   id: "Inwards",
  //   label: "Inwards",
  //   icon: <BadgeDollarSign />,
  //   link: "/inwards",
  //   parentId: 2,
  // },
  // {
  //   id: "expenses",
  //   label: "Expenses",
  //   icon: <BadgeDollarSign />,
  //   link: "/expenses",
  //   parentId: 2,
  // },

  {
    label: "Human Resources",
    isTitle: true,
  },
  {
    id: "departments",
    label: "Departments",
    icon: <BadgeDollarSign />,
    link: "/departments",
    parentId: 2,
  },
  {
    id: "employees",
    label: "Employees",
    icon: <BadgeDollarSign />,
    link: "/employees",
    parentId: 2,
  },
  {
    label: "Management",
    isTitle: true,
  },

  // {
  //   id: "office-management",
  //   label: "Office Management",
  //   link: "/office-management",
  //   icon: <Building />,
  //   subItems: [
  //     // {
  //     //   id: "expenses",
  //     //   label: "Expenses",
  //     //   link: "/expenses",
  //     //   parentId: "office-management",
  //     // },
  //     {
  //       id: "invoice",
  //       label: "Invoices",
  //       link: "/invoices",
  //       parentId: "office-management",
  //     },
  //     {
  //       id: "profit-record",
  //       label: "Profit Record",
  //       link: "/profit-record",
  //       parentId: "office-management",
  //     },
  //   ],
  // },

  {
    id: "renewals",
    label: "Renewals Management",
    icon: <FileText />,
    parentId: 2,
    subItems: [
      {
        id: "cars",
        label: "Vehicles",
        link: "/renewals/cars",
        parentId: "renewals",
      },
      {
        id: "drivers",
        label: "Drivers",
        link: "/renewals/drivers",
        parentId: "renewals",
      },
      {
        id: "company-internals",
        label: "Company Internals",
        link: "/renewals/company-internals",
        parentId: "renewals",
      },
    ],
  },
  {
    id: "reports",
    label: "Report Management",
    link: "/reports",
    icon: <FileText />,
    subItems: [
      {
        id: "cars",
        label: "Vehicles",
        link: "/reports/cars",
        parentId: "reports",
      },
      {
        id: "drivers",
        label: "Drivers",
        link: "/reports/drivers",
        parentId: "reports",
      },
    ],
    parentId: 2,
  },

  {
    label: "Users",
    isTitle: true,
  },
  {
    id: "users",
    label: "Users",
    link: "/users",
    icon: <UserRound />,
    parentId: 2,
  },
];

export { menuData };
