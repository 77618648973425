import Dashboard from "pages/Dashboard/Analytics";
import Drivers from "pages/Drivers/ListView";

import Deposits from "pages/Deposits/Deposits";
import Withdrawals from "pages/Withdrawals/Withdrawals";
import Referral from "pages/Referrals";
import Transactions from "pages/Transactions";
import UsersTable from "pages/Users/ListView";
import Support from "pages/Support/Support";
import Deposit from "pages/Deposits/Deposit";
import Withdrawal from "pages/Withdrawals/Withdrawal";

import UserProfile from "pages/UserProfile";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import Cars from "pages/Cars/Cars";
import Sales from "pages/Sales/Sales";
import Expenses from "pages/Expenses/Expenses";
import Renewals from "pages/Renewal/Renewal";
import Reports from "pages/Reports/Reports";
import Driver from "pages/Drivers/Driver";
import CarReport from "pages/Reports/CarReports";
import Departments from "pages/Departments/Departments";
import Employees from "pages/Employees/Employees";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
}

let authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/", component: Dashboard },
  { path: "/dashboard", component: Dashboard },

  // Users
  { path: "/users", component: UsersTable },

  // Drivers
  { path: "/drivers", component: Drivers },

  // Drivers
  { path: "/drivers/:id", component: Driver },

  // Deposits
  { path: "/cars", component: Cars },

  // Deposit
  { path: "/deposits/:id", component: Deposit },

  // Sales
  { path: "/inwards", component: Sales },

  // Expenses
  { path: "/expenses", component: Expenses },

  // Renewal
  { path: "/renewals/:renewalType", component: Renewals },

  // Reports
  { path: "/reports/cars", component: CarReport },
  { path: "/reports/drivers", component: Reports },

  // Department
  { path: "/departments", component: Departments },

  // Department
  { path: "/employees", component: Employees },

  // Invoices
  // { path: "/invoices", component: Invoices },

  // Profits
  // { path: "/profit-record", component: Profits },

  // Withdrawals
  { path: "/withdrawals", component: Withdrawals },

  // Withdrawal
  { path: "/withdrawals/:id", component: Withdrawal },

  // Referral
  { path: "/referral", component: Referral },

  // Transactions
  { path: "/transactions", component: Transactions },

  // Support
  { path: "/support", component: Support },

  // profile
  { path: "/user-profile", component: UserProfile },
];

const publicRoutes = [
  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
