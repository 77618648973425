import React, { useCallback, useEffect, useState, useRef } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { toast } from "react-hot-toast";
// icons
import { Download } from "lucide-react";

import { ToastContainer } from "react-toastify";
import axios from "axios";
import moment from "moment";
import ReportPdf from "components/ReportPdf/ReportPdf";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
// @ts-ignore
import { DateRangePicker } from 'react-date-range';
import { enUS } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Modal from "Common/Components/Modal";

const Reports = () => {
  const [driverList, setDriverList] = useState([]);
  const dateRangeRef = useRef<HTMLDivElement>(null);
  const [dataList, setDataList] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [installments, setInstallments] = useState<number>(0);
  const [data, setData] = useState<any>([]);
  const [report, setReport] = useState<any>(null);
  const [originalReport, setOriginalReport] = useState<any>(null);
  const [selectedDriver, setSelectedDriver] = useState<boolean>(false);
  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection"
  })

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [show]);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (dateRangeRef.current && !dateRangeRef.current.contains(event.target as Node)) {
      setShowDateRange(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const downloadPDF = () => {
    const input: HTMLElement | null = document.getElementById("report-pdf");
    const scale = 3; // Increase the scale
    html2canvas(input!, { scale }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdfWidth = 210; // A4 width in mm
      const pdfHeight = 297; // A4 height in mm

      const aspectRatio = canvas.width / canvas.height;

      let width = pdfWidth;
      let height = pdfWidth / aspectRatio;

      if (height > pdfHeight) {
        height = pdfHeight;
        width = pdfHeight * aspectRatio;
      }

      const pdf = new jsPDF({
        orientation: height > width ? "portrait" : "landscape",
        unit: "mm",
        format: "a4",
      });

      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.save(`report-${moment().format("DD-MM-YYYY")}.pdf`);
    });
  };

  function filterReportDataByRange(startDate: Date, endDate: Date): void {
    if (!selectedDriver) {
      toast.error("Please select a driver to filter the report data");
      return;
    }
    const filteredReport = { ...originalReport };

    // Adjust the endDate to include the entire day
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    // Filter renewals based on renewalDate
    filteredReport.renewals = originalReport.renewals?.filter(
      (renewal: any) => {
        const renewalDate = new Date(renewal.renewalDate);
        return renewalDate >= startDate && renewalDate <= adjustedEndDate;
      }
    );

    // Filter expenses based on expenseDate
    filteredReport.expenses = originalReport.expenses?.filter(
      (expense: any) => {
        const expenseDate = new Date(expense.expenseDate);
        return expenseDate >= startDate && expenseDate <= adjustedEndDate;
      }
    );

    // Filter sales based on saleDate
    filteredReport.sales = originalReport.sales?.filter(
      (sale: any) => {
        const saleDate = new Date(sale.saleDate);
        return saleDate >= startDate && saleDate <= adjustedEndDate;
      }
    );

    console.log("after filtering: ", filteredReport);
    setReport(filteredReport);
  }



  const getDriverReport = async (id: string) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/report/driver/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      toast.success("Driver report fetched successfully!");
      setReport(res.data);
      setOriginalReport(res.data);
    } catch (error) {
      toast.error("Failed to fetch driver report!");
      console.log(error);
    }
  };

  const handleFetchDrivers = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/driver/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setDriverList(res.data);
      setDriverList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            createdAt: moment(item.createdAt).format("DD MMMM, YYYY"),
          };
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchDrivers();
  }, []);

  useEffect(() => {
    setDataList(data);
  }, []);

  return (
    <React.Fragment>
      <BreadCrumb title="Driver Reports" pageTitle="Management" />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card " id="Drivers">
            <div className="card-body">
              <div className="flex flex-row relative items-center justify-between">
                <div className="flex items-center">
                  <h6 className="text-15 grow">Driver Report</h6>
                </div>

                <button
                  onClick={() => setShowDateRange(!showDateRange)}
                  type="button"
                  className="bg-white mt-4 border-dashed w-1/6 text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                >
                  <span className="align-middle">Filter</span>
                </button>
                {
                  showDateRange &&
                  <div
                  ref={dateRangeRef}
                  className="absolute right-0 top-16 z-[9999]">
                    <DateRangePicker
                      className="shadow-md rounded-md"
                      ranges={[dateRange]}
                      onChange={(item) => {
                        setDateRange(item.selection);
                        filterReportDataByRange(item.selection.startDate ?? new Date(), item.selection.endDate ?? new Date());
                      }}
                      locale={enUS}
                    />
                  </div>
                }
              </div>
              <div className=" w-full flex xl:flex-row flex-col gap-5 mt-2">
                <div className="xl:w-2/3 lg:w-full w-full " id="report-pdf">
                  <ReportPdf 
                  dateRange={dateRange}
                  installments={installments} report={report} />
                </div>
                <div className="xl:w-1/3 lg:w-full w-full">
                  <h5>Select Driver</h5>
                  <select
                    onChange={(e) => {
                      getDriverReport(e.target.value);
                      setSelectedDriver(true);
                    }}
                    className="border w-full dark:bg-transparent mt-2 p-1 rounded-md text-lg px-2"
                  >
                    <option value="" disabled selected>Select a driver</option>
                    {driverList.map((item: any) => {
                      return <option value={item._id}>{item.user?.name}</option>;
                    })}
                  </select>
                  <button
                    type="button"
                    className="bg-white mt-4 border-dashed w-full text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                    onClick={downloadPDF}
                    disabled={selectedDriver ? false : true}
                  >
                    <Download className="inline-block size-4" />{" "}
                    <span className="align-middle">Export</span>
                  </button>

                  <button
                    type="button"
                    className="bg-white mt-4 border-dashed w-full text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                    onClick={() => setReport(originalReport)}
                    disabled={selectedDriver ? false : true}
                  >
                    <span className="align-middle">Clear Filters</span>
                  </button>

                  <button
                    type="button"
                    className="bg-white mt-4 border-dashed w-full text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                    onClick={toggle}
                  >
                    <span className="align-middle">Select Installments</span>
                  </button>

                  <Modal
                    show={show}
                    onHide={toggle}
                    modal-center="true"
                    className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                    dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
                  >
                    <Modal.Header
                      className="flex items-center justify-between p-4 border-b dark:border-zink-500"
                      closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
                    >
                      <Modal.Title className="text-16">{"Select installments"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                      <input
                        type="text"
                        id="installments"
                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="12345678"
                        name="installments"
                        onChange={(e) => setInstallments(Number(e.target.value))}
                        value={installments}
                      />
                      <button
                        type="reset"
                        className="mt-5 w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                        onClick={toggle}
                      >
                        Save
                      </button>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Reports;
