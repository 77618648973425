import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import CountUp from "react-countup";
import PhotosUploader from "components/Forms/ImageUploader";
import { toast } from "react-hot-toast";
import { useAuthStore } from "store/useAuthStore";
import * as XLSX from "xlsx";

// icons
import {
  AlertTriangle,
  Download,
  Edit,
  Eye,
  Loader,
  MoreHorizontal,
  Printer,
  Search,
  Trash,
} from "lucide-react";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";

import { Dropdown } from "Common/Components/Dropdown";
import Modal from "Common/Components/Modal";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import moment from "moment";
import { use } from "i18next";
import { isDateWithinNext20Days, toReadableDate } from "helpers/utils";

const Cars = () => {
  const { user } = useAuthStore();
  const [creatingDeposit, setCreatingDeposit] = useState<boolean>(false);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [initialDriver, setInitialDriver] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editCarId, setEditCarId] = useState(null);
  // const [renewals, setRenewals] = useState<any>([]);

  const handleGetCars = async (renewals?: any) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/car/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      console.log(res.data);
      setDataList(res.data);
      setData(res.data);

      // THIS WHOLE PROCESS IS VERY TEDIOUS, I WILL REFACTOR IT LATER (i wont)
      // parse date to a readable format
      setData((prev: any) => {
        return prev.map((item: any) => {
          const renewal = renewals?.find((renewal: any) =>
            renewal.referenceId === item._id
          );

          return {
            ...item,
            createdAt: moment(item.createdAt).format("DD MMMM, YYYY"),
            assignedDriverDate: moment(item.assignedDriverDate).format(
              "DD MMMM, YYYY",
            ),
            renewalStatus: renewal ? renewal.status : "N/A",
          };
        });
      });

      setDataList((prev: any) => {
        return prev.map((item: any) => {
          const renewal = renewals?.find((renewal: any) =>
            renewal.referenceId === item._id
          );
          return {
            ...item,
            renewalStatus: renewal ? renewal.status : "N/A",
          };
        });
      });

      setDataList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            createdAt: moment(item.createdAt).format("DD MMMM, YYYY"),
            assignedDriverDate: moment(item.assignedDriverDate).format(
              "DD MMMM, YYYY",
            ),
          };
        });
      });
      setData((prev: any) => {
        return prev.map((item: any) => {
          if (!item.assignedDriver) {
            item.assignedDriver = { user: { name: "N/A" } };
          }
          return item;
        });
      });
      setDataList((prev: any) => {
        return prev.map((item: any) => {
          if (!item.assignedDriver) {
            item.assignedDriver = { user: { name: "N/A" } };
          }
          return item;
        });
      });
      setData((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            driverName: item.assignedDriver.user.name,
          };
        });
      });

      setDataList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            driverName: item.assignedDriver.user.name,
          };
        });
      });

      // assign user name to N/A if null
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetAllRenewals = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/renewal/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      // setRenewals(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchUsers = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/driver/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setUserList(res.data);
      setInitialDriver(res.data[0]._id);
      setUserList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            createdAt: moment(item.createdAt).format("DD MMMM, YYYY"),
          };
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      await handleGetAllRenewals().then((res) => {
        handleGetCars(res);
      });
      handleFetchUsers();
    };
    fetchAll();
  }, []);

  const deleteCar = async (id: string) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URI}/crm/car/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      handleGetCars();
      toast.success("Car deleted successfully!");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (images.length > 0) {
      validation.setFieldValue("carImage", images[0]);
    } else {
      validation.setFieldValue("carImage", "");
    }
  }, [images]);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      carImage: "",
      make: "",
      model: "",
      year: "",
      assignedDriver: "",
      registrationNumber: "",
      color: "",
      chassisNumber: "",
      validRegistration: "",
      plateNumber: "",
    },
    validationSchema: Yup.object({
      make: Yup.string().required("Please Enter Make"),
      year: Yup.string().required("Please Enter Year"),
      model: Yup.string().required("Please Enter Model"),
      assignedDriver: Yup.string(),
      registrationNumber: Yup.string().required(
        "Please Enter Registration Number",
      ),
      chassisNumber: Yup.string().required("Please Enter Chassis Number"),
      validRegistration: Yup.string().required(
        "Please Enter Valid Registration",
      ),
      plateNumber: Yup.string().required("Please Enter Plate Number"),
      color: Yup.string().required("Please Enter Color"),
      carImage: Yup.string().required("Please Upload Payment Proof"),
    }),

    onSubmit: async (values) => {
      const newData = {
        ...values,
      };
      // edit car
      if (showEdit) {
        setCreatingDeposit(true);
        try {
          await axios.put(
            `${process.env.REACT_APP_BASE_URI}/crm/car/${editCarId}`,
            {
              ...newData,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          );
          handleGetCars();
          toast.success("Car updated successfully!");
          toggleEditModal(null);
          validation.resetForm();
          setImages([]);
        } catch (error: any) {
          if (!error.response) {
            return toast.error("Network error. Please try again.");
          }
          if (typeof error.response.data === "string") {
            return toast.error(error.response.data);
          }
        } finally {
          setCreatingDeposit(false);
        }
        return;
      } // create new car
      else {
        setCreatingDeposit(true);
        try {
          await axios.post(
            `${process.env.REACT_APP_BASE_URI}/crm/car`,
            {
              ...newData,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          );
          handleGetCars();
          toast.success("Car made successfully!");
          toggle();
          validation.resetForm();
          setImages([]);
        } catch (error: any) {
          if (!error.response) {
            return toast.error("Network error. Please try again.");
          }
          if (typeof error.response.data === "string") {
            return toast.error(error.response.data);
          }
        } finally {
          setCreatingDeposit(false);
        }
      }
    },
  });

  const toggleEditModal = useCallback((data: any | null) => {
    if (showEdit) {
      setShowEdit(false);
      setEditCarId(null);
      setImages([]);
    } else {
      setShowEdit(true);
      setEditCarId(data._id);
      validation.setFieldValue("assignedDriver", data.assignedDriver._id);
      validation.setFieldValue("carImage", data.carImage);
      validation.setFieldValue("make", data.make);
      validation.setFieldValue("model", data.model);
      validation.setFieldValue("registrationNumber", data.registrationNumber);
      validation.setFieldValue("year", data.year);
      validation.setFieldValue("color", data.color);
      validation.setFieldValue("chassisNumber", data.chassisNumber);
      validation.setFieldValue("validRegistration", data.validRegistration);
      validation.setFieldValue("plateNumber", data.plateNumber);
      setImages([data.carImage]);
    }
  }, [showEdit, validation]);

  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
      validation.resetForm();
    }
  }, [show, validation]);

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ["_id", "make", "model", "driverName"];
    const searchResult = dataList.filter((item: any) => {
      return keysToSearch.some((key) => {
        return (
          item[key] &&
          item[key].toString().toLowerCase().includes(search.toLowerCase())
        );
      });
    });
    setData(searchResult);
  };

  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    setDataList(data);
  }, []);

  const toggleTab = (tab: any, type: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredDeposits = dataList;
      if (type !== "all") {
        filteredDeposits = dataList.filter((car: any) => car.status === type);
      }
      setData(filteredDeposits);
    }
  };

  // columns
  const Status = ({ item }: any) => {
    switch (item) {
      case "Approved":
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">
            {item}
          </span>
        );
      case "Pending":
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-yellow-100 border-yellow-200 text-yellow-500 dark:bg-yellow-500/20 dark:border-yellow-500/20">
            {item}
          </span>
        );
      case "Rejected":
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-red-100 border-red-200 text-red-500 dark:bg-red-500/20 dark:border-red-500/20">
            {item}
          </span>
        );
      default:
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">
            {item}
          </span>
        );
    }
  };

  const columns = useMemo(
    () => [
      {
        // header: (
        //   <div className="flex items-center h-full">
        //     <input
        //       id="CheckboxAll"
        //       className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
        //       type="checkbox"
        //     />
        //   </div>
        // ),
        // enableSorting: false,
        id: "checkAll",
        // cell: (cell: any) => {
        //   return (
        //     <div className="flex items-center h-full">
        //       <input
        //         id={"Checkbox" + cell.row.original.id}
        //         className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
        //         type="checkbox"
        //       />
        //     </div>
        //   );
        // },
      },
      // {
      //   header: "ID",
      //   accessorKey: "_id",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: (cell: any) => (
      //     <>
      //       <Link
      //         to="#!"
      //         className="transition-all duration-150 ease-linear order_id text-custom-500 hover:text-custom-600"
      //       >
      //         {cell.getValue()}
      //       </Link>
      //     </>
      //   ),
      // },
      {
        header: "Make",
        accessorKey: "make",
        enableColumnFilter: false,
      },
      {
        header: "Model",
        accessorKey: "model",
        enableColumnFilter: false,
      },
      {
        header: "Year",
        accessorKey: "year",
        enableColumnFilter: false,
      },
      {
        header: "Registration Number",
        accessorKey: "registrationNumber",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },
      // {
      //   header: "Driver",
      //   accessorKey: "driverName",
      //   enableColumnFilter: false,
      // },
      {
        header: "Registration Expiry",
        accessorKey: "validRegistration",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <p>
              {isDateWithinNext20Days(cell.row.original.validRegistration)
                ? (
                  <p
                    title="Registration expiring soon!"
                    className="flex flex-row justify-around text-red-500 items-center bg-red-50 rounded-md p-1"
                  >
                    <AlertTriangle className="text-red-500" size={15} />
                    {toReadableDate(cell.row.original.validRegistration)}
                  </p>
                )
                : (
                  <p className="">
                    {toReadableDate(cell.row.original.validRegistration)}
                  </p>
                )}
            </p>
          </div>
        ),
      },
      {
        header: "Assigned Driver On",
        accessorKey: "assignedDriverDate",
        enableColumnFilter: false,
      },
      {
        header:"Vehicle Number Plate",
        accessorKey:"plateNumber",
        enableColumnFilter:false,
      },
      {
        header: "Renewal Status",
        accessorKey: "renewalStatus",
        enableColumnFilter: false,
      },
      {
        header: "Created On",
        accessorKey: "createdAt",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              id="orderAction1"
              data-bs-toggle="dropdown"
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement={cell.row.index ? "top-end" : "right-end"}
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="orderAction1"
            >
              {
                /* <li>
                <Link
                  to={`/deposits/${cell.row.original._id}`}
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                >
                  <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Overview</span>
                </Link>
              </li> */
              }
              <li
                onClick={() => toggleEditModal(cell.row.original)}
              >
                <div // to={`/cars/edit/${cell.row.original._id}`}
                 className="block px-4 py-1.5 text-base transition-all cursor-pointer duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  <Edit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                  {" "}
                  <span className="align-middle">Edit</span>
                </div>
              </li>
              <li onClick={() => deleteCar(cell.row.original._id)}>
                <p className="block cursor-pointer px-4 py-1.5 text-base transition-all duration-200 ease-linear text-red-500 hover:bg-red-100 hover:text-red-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  <Trash className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                  {" "}
                  <span className="align-middle">Delete</span>
                </p>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    [],
  );

  const downloadCarsInExcel = async () => {
    const data = dataList.map((item: any) => {
      return {
        ID: item._id,
        Make: item.make,
        Model: item.model,
        Year: item.year,
        RenewalStatus: item.renewalStatus,
        AssignedDriverOn: item.assignedDriverDate,
        Driver: item.assignedDrivers ? item.assignedDrivers?.user?.name : "N/A",
        RegistrationNumber: item.registrationNumber,
        CreatedOn: item.createdAt,
      };
    });
    const fileName = "Cars";
    const exportType = "xls";
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.${exportType}`);
  };

  function handlePrint() {
    window.print();
  }

  return (
    <React.Fragment>
      <BreadCrumb title="All Cars" pageTitle="Cars" />
      <ToastContainer closeButton={false} limit={1} />
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      <div className="card" id="ordersTable">
        <div className="card-body">
          <div className="flex gap-2">
            <div className="ml-auto flex flex-row gap-2">
              <button
                onClick={handlePrint}
                type="button"
                className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
              >
                <Printer className="inline-block size-4" />{" "}
                <span className="align-middle">Print page</span>
              </button>
              <button
                onClick={downloadCarsInExcel}
                type="button"
                className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
              >
                <Download className="inline-block size-4" />{" "}
                <span className="align-middle">Export</span>
              </button>
            </div>
          </div>
          <div className="flex items-center">
            <h6 className="text-15 grow">Cars List</h6>
          </div>
          <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
            <form action="#!">
              <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                <div className="relative xl:col-span-2">
                  <input
                    type="text"
                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Search for name, email, phone number etc..."
                    autoComplete="off"
                    onChange={(e) => filterSearchData(e)}
                  />
                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                </div>

                <div className="xl:col-span-3 xl:col-start-10">
                  <div className="flex gap-2 xl:justify-end">
                    <div>
                      <button
                        type="button"
                        className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                        onClick={toggle}
                      >
                        <span className="align-middle">Add car</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {data && data.length > 0
            ? (
              <TableContainer
                isPagination={true}
                columns={columns || []}
                data={data || []}
                customPageSize={10}
                divclassName="mt-5 overflow-x-auto"
                tableclassName="w-full whitespace-nowrap"
                theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                thclassName="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:border-zink-500 dark:text-zink-200"
                tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              />
            )
            : (
              <div className="noresult">
                <div className="py-6 text-center">
                  <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                  <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                  <p className="mb-0 text-slate-500 dark:text-zink-200">
                    We've searched all vehicles, but we did not find any
                    vehicles for your search.
                  </p>
                </div>
              </div>
            )}
        </div>
      </div>

      {/* Order Modal */}

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">{"Add a Car"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="">
              <div className="grid grid-cols-2 gap-2">
                <div className="">
                  <label
                    htmlFor="model"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Model
                  </label>
                  <input
                    type="text"
                    id="model"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Camry"
                    name="model"
                    onChange={validation.handleChange}
                    value={validation.values.model || ""}
                  />
                  {validation.touched.model && validation.errors.model
                    ? <p className="text-red-400">{validation.errors.model}</p>
                    : null}
                </div>
                <div className="">
                  <label
                    htmlFor="make"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Make
                  </label>
                  <input
                    type="text"
                    id="make"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Toyota"
                    name="make"
                    onChange={validation.handleChange}
                    value={validation.values.make || ""}
                  />
                  {validation.touched.make && validation.errors.make
                    ? <p className="text-red-400">{validation.errors.make}</p>
                    : null}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="">
                  <label
                    htmlFor="plateNumber"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Plate Number
                  </label>
                  <input
                    type="text"
                    id="plateNumber"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="0000"
                    name="plateNumber"
                    onChange={validation.handleChange}
                    value={validation.values.plateNumber || ""}
                  />
                  {validation.touched.plateNumber &&
                      validation.errors.plateNumber
                    ? (
                      <p className="text-red-400">
                        {validation.errors.plateNumber}
                      </p>
                    )
                    : null}
                </div>
                <div className="">
                  <label
                    htmlFor="chassisNumber"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Chassis Number
                  </label>
                  <input
                    type="text"
                    id="chassisNumber"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="0000"
                    name="chassisNumber"
                    onChange={validation.handleChange}
                    value={validation.values.chassisNumber || ""}
                  />
                  {validation.touched.chassisNumber &&
                      validation.errors.chassisNumber
                    ? (
                      <p className="text-red-400">
                        {validation.errors.chassisNumber}
                      </p>
                    )
                    : null}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2">
                <div className="">
                  <label
                    htmlFor="registrationNumber"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Registration Number
                  </label>
                  <input
                    type="text"
                    id="registrationNumber"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="12345 67890"
                    name="registrationNumber"
                    onChange={validation.handleChange}
                    value={validation.values.registrationNumber || ""}
                  />
                  {validation.touched.registrationNumber &&
                      validation.errors.registrationNumber
                    ? (
                      <p className="text-red-400">
                        {validation.errors.registrationNumber}
                      </p>
                    )
                    : null}
                </div>
                <div className="">
                  <label
                    htmlFor="validRegistration"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Registration Valid for
                  </label>
                  <input
                    type="date"
                    id="validRegistration"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="12345 67890"
                    name="validRegistration"
                    onChange={validation.handleChange}
                    value={validation.values.validRegistration || ""}
                  />
                  {validation.touched.validRegistration &&
                      validation.errors.validRegistration
                    ? (
                      <p className="text-red-400">
                        {validation.errors.validRegistration}
                      </p>
                    )
                    : null}
                </div>
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="year"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Year
                </label>
                <input
                  type="number"
                  id="year"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="2024"
                  name="year"
                  onChange={validation.handleChange}
                  value={validation.values.year || ""}
                />
                {validation.touched.year && validation.errors.year
                  ? <p className="text-red-400">{validation.errors.year}</p>
                  : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="assignedDriver"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Assign Driver
                </label>
                <select
                  name="assignedDriver"
                  id="assignedDriver"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.assignedDriver || ""}
                  onChange={validation.handleChange}
                  required
                >
                  <option value={"N/A"}>N/A</option>
                  {userList.map((driver) => (
                    // @ts-ignore
                    <option value={driver._id}>{driver.user.name}</option>
                  ))}
                </select>
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="color"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Color
                </label>
                <select
                  name="color"
                  id="color"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.color || ""}
                  onChange={validation.handleChange}
                  required
                >
                  <option value={"N/A"}>N/A</option>
                  <option value={"black"}>Black</option>
                  <option value={"white"}>White</option>
                  <option value={"gold"}>Gold</option>
                  <option value={"gray"}>Gray</option>
                </select>
              </div>
              <div className="">
                <label
                  htmlFor="fileUpload"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Upload Image
                </label>
                <PhotosUploader
                  maxPhotos={1}
                  addedPhotos={images}
                  onChange={(photos: any) => {
                    setImages(photos);
                  }}
                />
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {"Add a Car"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Edit Modal */}
      <Modal
        show={showEdit}
        onHide={toggleEditModal}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">{"Edit Car"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-12">
                <label
                  htmlFor="model"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Model
                </label>
                <input
                  type="text"
                  id="model"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Camry"
                  name="model"
                  onChange={validation.handleChange}
                  value={validation.values.model || ""}
                />
                {validation.touched.model && validation.errors.model
                  ? <p className="text-red-400">{validation.errors.model}</p>
                  : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="year"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Year
                </label>
                <input
                  type="number"
                  id="year"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="2024"
                  name="year"
                  onChange={validation.handleChange}
                  value={validation.values.year || ""}
                />
                {validation.touched.year && validation.errors.year
                  ? <p className="text-red-400">{validation.errors.year}</p>
                  : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="registrationNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Registration Number
                </label>
                <input
                  type="text"
                  id="registrationNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="12345 67890"
                  name="registrationNumber"
                  onChange={validation.handleChange}
                  value={validation.values.registrationNumber || ""}
                />
                {validation.touched.registrationNumber &&
                    validation.errors.registrationNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.registrationNumber}
                    </p>
                  )
                  : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="make"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Make
                </label>
                <input
                  type="text"
                  id="make"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Toyota"
                  name="make"
                  onChange={validation.handleChange}
                  value={validation.values.make || ""}
                />
                {validation.touched.make && validation.errors.make
                  ? <p className="text-red-400">{validation.errors.make}</p>
                  : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="assignedDriver"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Assign Driver
                </label>
                <select
                  name="assignedDriver"
                  id="assignedDriver"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.assignedDriver || ""}
                  onChange={validation.handleChange}
                  required
                >
                  <option value={"N/A"}>N/A</option>
                  {userList.map((driver) => (
                    // @ts-ignore
                    <option value={driver._id}>{driver.user.name}</option>
                  ))}
                </select>
                <div className="mt-2 flex flex-row gap-2 opacity-70">
                  <strong>Currently assigned to:</strong>
                  <p>
                    {data.find((car: any) => car._id == editCarId)
                        ?.assignedDrivers != null
                      ? (
                        <p>
                          {data.find((car: any) => car._id == editCarId)
                            ?.assignedDrivers.user.name}
                        </p>
                      )
                      : <p></p>}
                  </p>
                </div>
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="fileUpload"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Upload Image
                </label>
                <PhotosUploader
                  maxPhotos={1}
                  addedPhotos={images}
                  onChange={(photos: any) => {
                    setImages(photos);
                  }}
                />
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggleEditModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {"Update car"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Cars;
